import { useEffect, useState } from "react";
import { useLocation, useOutlet } from "react-router-dom";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";

export const HomeLayout = () => {
    const location = useLocation();
    const isHome = location.pathname === '/landing';
    const outlet = useOutlet();
    const [ overlay, setOverlay ] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    
  
    return (
        <>
            <div 
            className={`fixed top-0 ${overlay ? 'w-full' : "w-0" } h-full md:hidden bg-color3 transition-all ease-linear 
            duration-100 overflow-hidden z-[999]`}></div>
            {!isHome && <Navbar isHome={isHome} setOverlay={setOverlay} />}
                { outlet }
            <Footer />
        </>
    )
};
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonDark } from '../../components/buttons/Buttons';

const Austria = () => {
  const navigate = useNavigate();
  return (
    <section className='max-w-3xl mx-auto my-4'>
      <h1 className=" text-darkblue font-bold text-4xl text-center lg:text-left md:text-6xl font-playfair"> Austria </h1>
      <section className='text-base sm:text-lg text-color4 text-center lg:text-left font-normal mt-3 '>
        <section className='mt-3'>
          Austria is a member of the Schengen Area, which means that visitors from certain countries are allowed to enter Austria without a visa and stay for up to 90 days within a 180-day period. 
          However, visitors from other countries may need to apply for a visa before traveling to Austria. 
          Here are some details regarding visa and passport processing for Austria:
        </section>
        <section className='mt-3'>
          <p className='text-darkblue font-medium'>
            Visa Requirements:
          </p>
            Visitors from the European Union (EU), European Economic Area (EEA), and Switzerland do not need a visa to enter Austria. 
            Visitors from many other countries, including the United States, Canada, Australia, and Japan, also do not need a visa for short-term stays of up to 90 days within a 180-day period. 
            However, visitors from some countries, such as China, India, and Russia, need to apply for a Schengen visa before traveling to Austria.
        </section>
        <section className='mt-3'>
          <p className='text-darkblue font-medium'>
            Visa Application Process:
          </p>
            If you need a visa to travel to Austria, you can apply at the Austrian embassy or consulate in your home country. 
            You will need to fill out a visa application form and provide supporting documents, such as a passport, travel itinerary, and proof of sufficient funds. 
            The visa processing time can vary, but it usually takes around 15 days.
        </section>
        <section className='mt-3'>
          <p className='text-darkblue font-medium'>
            Passport Requirements:
          </p>
              Visitors to Austria need a passport that is valid for at least three months beyond the planned departure date. Some countries may also require a visa or other travel documents.
        </section>
        <section className='mt-3'>
          <p className='text-darkblue font-medium'>
              Passport Application Process:
          </p>
                If you need to apply for a passport, you can do so at your local passport office. You will need to provide proof of identity, such as a birth certificate or driver's license, and two passport-sized photos. 
                The processing time for a passport application can vary, but it usually takes around 4 to 6 weeks
        </section>

        <section className="flex justify-center mt-8">
          <ButtonDark handleClick={() => navigate("/visa-application")}>
            Apply For Visa Now
          </ButtonDark>
        </section>
          

      </section>




    </section>
  );
}

export default Austria
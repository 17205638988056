import { Box } from "../../box/Box";
import airplane from "../../../assets/icons/airplane.png";
import support from "../../../assets/icons/technical-support.png";
import agile from "../../../assets/icons/agile.png";
import verified from "../../../assets/icons/verified.png";
import breakdown from "../../../assets/icons/money-loss.png";
import approved from "../../../assets/icons/approved.png";
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { BoxMobile } from "../../box/BoxMobile";

export const Boxes = () => {
    const all = [
        { heading: "Ease of access", body: "Simple and user-friendly visa application process.", img: airplane, alt: 'aiplane' },
        { heading: "Customer Support", body: "Expert guidance and assistance throughout the visa application process", img: support, alt: 'support' },
        { heading: "Quick Processing", body: "Fast and efficient processing times.", img: agile, alt: 'agile' },
        { heading: "Security of Information", body: "Secure and confidential handling of personal information", img: verified, alt: 'verified' },
        { heading: "Cost Breakdown", body: "We provide a breakdown of all costs associated with your visa application", img: breakdown, alt: 'breakdown' },
        { heading: "Get approved", body: "Extensive knowledge of visa requirements for various countries", img: approved, alt: 'approved' },
    ];
    const contents = [
        { heading: "Ease of access", body: "Simple and user-friendly visa application process.", img: airplane, alt: 'aiplane' },
        { heading: "Customer Support", body: "Expert guidance and assistance throughout the visa application process", img: support, alt: 'support' },
        { heading: "Quick Processing", body: "Fast and efficient processing times.", img: agile, alt: 'agile' }
    ];
    const contents2 = [
        { heading: "Security of Information", body: "Secure and confidential handling of personal information", img: verified, alt: 'verified' },
        { heading: "Cost Breakdown", body: "We provide a breakdown of all costs associated with your visa application", img: breakdown, alt: 'breakdown' },
        { heading: "Get approved", body: "Extensive knowledge of visa requirements for various countries", img: approved, alt: 'approved' },
    ];
    return (
        <>
            <section className="md:hidden">
                <Swiper
                slidesPerView={1}
                spaceBetween={30}
                pagination={{
                clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper mt-10"
                >
                { contents.map((content, id) => (
                    <SwiperSlide key={id}>
                        <section className="flex justify-center items-center w-fit mx-auto">
                            <BoxMobile key={id} heading={content.heading} text={content.body} img={content.img} alt={content.alt} />
                        </section>
                    </SwiperSlide>
                    )) }
                </Swiper>
            </section>

            <section className="md:hidden">
                <Swiper
                slidesPerView={1}
                spaceBetween={30}
                pagination={{
                clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper mt-10"
                >
                { contents2.map((content, id) => (
                    <SwiperSlide key={id}>
                        <section className="flex justify-center items-center w-fit mx-auto">
                            <BoxMobile key={id} heading={content.heading} text={content.body} img={content.img} alt={content.alt} />
                        </section>
                    </SwiperSlide>
                    )) }
                </Swiper>
            </section>

            <section className="flex-wrap gap-4 mt-10 items-center justify-center hidden md:flex">
            { all.map((content, id) => (
                <Box key={id} heading={content.heading} text={content.body} img={content.img} alt={content.alt} />
            )) }
        </section>
        
        </>
    )
}
import React, { useCallback, useEffect, useState } from "react";
import { ButtonDark } from "../../buttons/Buttons";
import { SubHeading } from "../userDetailsForm/SubHeading";
import { PassportRequirements } from "./PassportRequirements";
import { RequirementBox } from "./RequirementBox";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { AiOutlineCloudUpload } from "react-icons/ai";
import "./Styles.css";
import { useDropzone } from "react-dropzone";
import { ErrorMsg } from "../../../alert/ErrorMsg";
import { useDispatch, useSelector } from "react-redux";
import { deleteOneFile, removeFile, updatePassportDraft } from "../../../features/unauth-features/passportSlice";
import { ErrorToast, SuccessToast } from "../../toast/toasts";
import { FileUpdateBox } from "./FileUpdateBox";




const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes;


const schema = yup.object().shape({
  files: yup
  .array()
  .min(1, 'At least one file is required')
  .test(
    'totalSize',
    'Total file size should not exceed 5MB',
    (files) =>
      files.reduce((acc, file) => acc + file.size, 0) <= maxFileSize,
  )
  .test(
    'fileType',
    'Invalid file type',
    (files) => {
      const allowedExtensions = ['.jpg', '.jpeg', '.doc', '.pdf', '.png', '.msword'];
      return files.every((file) => {
        const fileExtension = `.${file.name.split('.').pop()}`;
        return allowedExtensions.includes(fileExtension);
      });
    }
  ),
});

const requirements = [
  { shortName: "Letter of recommendation", longName: "Letter of recommendation/Identification", size: "200 KB"},
  { shortName: "Birth certificate", longName: "Birth certificate", size: "200 KB"},
  { shortName: "National Id card", longName: "National Id card or age declaration.", size: "200 KB" }
]

function UploadDocumentsAdult({ setRoute, routes }) {
  const {  passportDraft } = useSelector((state) => state.passport);
  const [loading, setLoading] = useState(false);
  const [ updateFiles, setUpdateFiles ] = useState([]);
  const [ passportId, setPassportId ] = useState("");
  

  const dispatch = useDispatch();
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),    
    defaultValues: {
      files: []
    }

  });

  const [files, setFiles] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        setFiles((prevFiles) => [...prevFiles, {...file, buffer: reader.result, name: file.name, size: file.size, type: file.name.split('.')[1]}]);
      }
      reader.readAsDataURL(file);
    })
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
  });
 

    const handleRemoveFile = (fileToRemove) => {
      setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    };

    const handleRemoveUpdateFile = (id) => {
      const payload = {
        passportId,
        fileId: id, 
      }
      dispatch(removeFile(payload))
      .unwrap()
      .then((res) => {
        dispatch(deleteOneFile(id));
        const updateFromStorage = JSON.parse(localStorage.getItem('passportUpdate'));
        setUpdateFiles(updateFromStorage.documentFile);
        SuccessToast("File deleted successfully");
      })
      .catch((err) => {
        ErrorToast(err?.data?.error || "Error occurred");
      })
    };
    


  const handleClick = (data) => {
    setLoading(true);
    const payload = {
      id: passportDraft.result._id,
      data: {
        files : []
      }
    };
    for (let i = 0; i < data.files.length; i++) {
      const obj = data.files[i];
      const items = {
        fileName: obj.name,
        mimeType: obj.type,
        buffer: obj.buffer,
      }
      payload.data.files.push(items);
    }
    dispatch(updatePassportDraft(payload))
    .unwrap()
    .then((res) => {
        setLoading(false);
        SuccessToast('Details uploaded successfully');
        setRoute(routes[2]);
    })
    .catch((err) => {
        setLoading(false);
        ErrorToast(err?.data?.error || err?.data?.message || err?.data || "Error occurred");
    })
  }

  useEffect(() => {
    // console.log(files)
    setValue("files", files)
    
  }, [files, setValue]);

  useEffect(() => {
    const updateFromStorage = JSON.parse(localStorage.getItem('passportUpdate'));
    if (updateFromStorage) {
      setPassportId(updateFromStorage._id);
      setUpdateFiles(updateFromStorage.documentFile);
    }

  }, []);

  return (
    <>
      <section>
        <SubHeading text={"Upload Documents"} />
        <section className="flex flex-row gap-x-8 my-[30px]">
          <PassportRequirements heading={"Passport Requirements"} requirements={requirements} />
          <section className="pass-form basis-full md:basis-3/4">
            <section className="upload-form bg-white shadow-round rounded-md mb-[20px] p-[20px] sm:py-[40px] sm:px-[70px]">
              <section  {...getRootProps({className: 'dropzone mx-auto p-[30px] rounded-md cursor-pointer text-center'})}>
                <section className="bg-[#d1d3d5] rounded-full p-2 w-fit mx-auto">
                        <AiOutlineCloudUpload className="flex w-fit justify-center " />
                </section>
                <section>
                    <section className="text">
                        <span className="text-color6">Click to upload</span> or drag
                        and drop
                    </section>
                    <section className="text"> pdf, doc, jpg or png </section>
                      <input
                      {...getInputProps()}
                      />
                        { errors["files"] && <ErrorMsg msg={errors["files"].message} /> }
                  </section>
              </section>
              <section className="my-[15px] grid grid-cols-1 md:grid-cols-2 gap-2 req-lists">
                { files.length > 0 && files.map((item, id) => (
                  <RequirementBox key={id} item={item} removeFile={handleRemoveFile} />
                ))}
              </section>
              {  updateFiles.length > 0 &&
                <section className="mt-10">
                  <h2 className="text-darkblue font-semibold text-center text-xl"> Previously Uploaded Files </h2>
                  <section className="my-[20px] grid grid-cols-1 md:grid-cols-2 gap-2 req-lists">
                    { updateFiles.length > 0 && updateFiles.map((item, id) => (
                      <FileUpdateBox key={id} item={item} removeFile={handleRemoveUpdateFile} />
                    ))}
                  </section>
                </section>

              }
              <section className="mt-6 flex w-fit" >
                <ButtonDark 
                disabled={loading}
                handleClick={handleSubmit(handleClick)}
                >
                    { loading ? <p className={`${loading && "loading-dot"} pr-2`}> Loading  </p> : 'Continue'}
                </ButtonDark>
              </section>
            </section>
          </section>
        </section>
      </section>
    </>
  );
}

export default UploadDocumentsAdult;

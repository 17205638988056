import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonDark } from '../../components/buttons/Buttons';

const Usa = () => {
  const navigate = useNavigate();
  return (
    <section className='max-w-3xl mx-auto my-10'>
      <h1 className=" text-darkblue font-bold text-4xl text-center lg:text-left md:text-6xl font-playfair"> Usa </h1>
      <section className='text-base sm:text-lg text-color4 text-center lg:text-left font-normal mt-3 '>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Visa Requirements:
          </p>
          <p>
            Visa requirements for the United States depend on the nationality of the visitor. 
            Visitors from certain countries may be eligible for the Visa Waiver Program (VWP), which allows them to visit the United States for up to 90 days without a visa. 
            Visitors from other countries need to apply for a visa to enter the United States.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Visa Application Process:
          </p>
          <p>
            If you need to apply for a visa to travel to the United States, you can do so online or in person at a U.S. embassy or consulate in your home country. 
            You will need to fill out a visa application form, provide supporting documents, such as a passport, travel itinerary, and proof of sufficient funds, and 
            pay the visa fee. 
            The processing time for a visa application can vary depending on the type of visa you are applying for, but it usually takes several weeks.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Passport Requirements:
          </p>
          <p>
            Visitors to the United States need a passport that is valid for at least six months beyond the planned departure date. 
            Some countries may also require a visa or other travel documents.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
              Passport Application Process:
          </p>
          <p>
            If you need to apply for a passport, you can do so at a passport acceptance facility in the United States or at a U.S. embassy or consulate abroad. 
            You will need to provide proof of identity, such as a birth certificate or driver's license, and two passport-sized photos. 
            The processing time for a passport application can vary depending on the type of passport you are applying for, but it usually takes several weeks.
          </p>
        </p>
  
        <section className="flex justify-center mt-8">
          <ButtonDark handleClick={() => navigate("/visa-application")}>
            Apply For Visa Now
          </ButtonDark>
        </section>
          

      </section>




    </section>
  );
}

export default Usa
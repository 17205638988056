import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";

export const PreviousLabel = () => {
    return (
        <button className="border flex items-center bg-white border-[#D0D5DD] text-[15px] font-normal text-gray-700 rounded-lg shadow-sm py-1.5 px-3 justify-center">
             <GrFormPreviousLink className="text-gray-700 font-semibold text-2xl my-auto mr-1.5" /> <span> Previous </span> 
        </button>
    )
}

export const NextLabel = () => {
    return (
        <button className="border flex items-center bg-white border-[#D0D5DD] text-[15px] font-normal text-gray-700 rounded-lg shadow-sm py-1.5 px-3 justify-center">
             <span> Next </span>  <GrFormNextLink className="text-gray-700 text-2xl my-auto ml-1.5 " /> 
        </button>
    )
}
const Approved = () => {
    return <p className="text-approved w-fit bg-approved_bg text-[12px] py-0.5 px-2 font-medium rounded-2xl"> Approved </p>
}

const InProgress = () => {
    return <p className="text-pending w-fit bg-pending_bg text-[12px] py-0.5 px-2 font-medium rounded-2xl"> In-progress </p>
}
const Pending = () => {
    return <p className="text-pending w-fit bg-pending_bg text-[12px] py-0.5 px-2 font-medium rounded-2xl"> Pending </p>
}

const Declined = () => {
    return <p className="text-declined w-fit bg-declined_bg text-[12px] py-0.5 px-2 font-medium rounded-2xl"> Declined </p>
}


const Status = ({value}) => {
    let status = value?.toLowerCase();
    return (
        <>
            { status === 'approved' &&  <Approved /> }
            { status === 'pending' &&  <Pending /> }
            { status === 'in-progress' &&  <InProgress /> }
            { status === 'declined' &&  <Declined /> }
        </>
    );
}
 
export default Status;
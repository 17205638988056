import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonDark } from "../../components/buttons/Buttons";


const Brazil = () => {
  const navigate = useNavigate();
  return (
    <section className='max-w-3xl mx-auto my-10'>
      <h1 className=" text-darkblue font-bold text-4xl text-center lg:text-left md:text-6xl font-playfair"> Brazil </h1>
      <section className='text-base sm:text-lg text-color4 text-center lg:text-left font-normal mt-3 '>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Visa Requirements:
          </p>
          <p>
           Depending on your nationality and the purpose and length of your visit, you may need to apply for a visa to enter Brazil. The Brazilian government provides a list of countries that are exempt from visa requirements for visits of up to 90 days. If you are not from one of these countries, you will need to apply for a visa before you travel to Brazil. </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Visa Application Process:
          </p>
          <p>
          Visa processing times can also vary depending on the type of visa and your country of origin. It's recommended that you apply for your visa well in advance of your travel dates to avoid any delays.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Passport Requirements:
          </p>
          <p>
          Visitors to Brazil must have a passport that is valid for at least six months beyond the intended departure date from Brazil. Your passport should also have at least two blank pages for entry and exit stamps.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
              Passport Application Process:
          </p>
          <p>
          If you need to renew your passport while in Brazil, you can apply at your embassy or consulate. Processing times may vary, so it's best to apply well in advance of your travel dates.
          </p>
        </p>

        <section className='flex justify-center mt-8'>
          <ButtonDark handleClick={() => navigate("/visa-application")}>
                    Apply For Visa Now
                </ButtonDark>
        </section>


      </section>




    </section>
  );
}

export default Brazil
import "./Box.css";


export const Box = ({ heading, text, img, alt }) => {
    return (
      <section
      >
        <section 
         className="border-[0.7px] border-dashed border-pending box transition-all duration-300 ease-in w-[260px] xxs:w-[300px] sm:w-[300px] lg:w-[350px] rounded-md pt-[50px] pl-[5%] md:pl-[3%] h-[280px]"
        >
          <h3 className="text-2xl sm:text-3xl mb-3 text-darkblue font-semibold"> { heading } </h3>
          <section className="flex justify-center w-full">
            <img src={img} width={60} height={60} alt={alt} />
          </section>
          <p className="text-lg max-w-[280px] font-normal text-black"> { text } </p>
        </section>
      </section>
    );
  };
  
import React, { useState } from "react";
import Search from "./Search";
import SearchTab from "./SearchTab";
import "./Table.css"
import { flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";

const Table = ({ data, columns, filter }) => {

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');

const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);
  
    // Store the itemRank info
    addMeta({
      itemRank,
    });
  
    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter,
      columnFilters,
    },
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  



 
    return (
        <section className="">
            <section className="">
                { filter &&
                    <section className="py-4 px-4 flex flex-wrap justify-center lg:justify-between space-y-3 lg:space-y-0">
                        <SearchTab setGlobalFilter={setGlobalFilter}/>
                        <section className="flex flex-wrap justify-center space-x-3 space-y-3 xs:space-y-0 items-center h-fit">
                            <Search
                            value={globalFilter ?? ''}
                            onChange={(e) => setGlobalFilter(e.target.value)}
                            />
                            <button className="bg-pending border-0 rounded-[5px] text-sm font-medium text-white px-4 h-fit py-2 my-auto"> Search </button>
                        </section>
                    </section>
                }
                
               { filter && <div className="h-[1px] bg-[#E4E7EC] my-2"></div>}
                
                <section className="overflow-x-auto w-full h-fit overflow-y-auto">
                    <table className="w-full min-w-[1200px]">
                        <thead className="text-sm text-color3 font-medium">
                            { table.getHeaderGroups().map((headerGroup, id) => (
                                <tr key={headerGroup.id}>
                                    { headerGroup.headers.map((header) => (
                                        <th className="py-2.5 pl-10">
                                            {
                                                flexRender(header.column.columnDef.header,header.getContext())
                                            }
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => {
                                return (
                                    <tr key={row.id}>
                                        { row.getVisibleCells().map((cell) => (
                                            <td key={cell.id} className=" py-3.5 pl-10"> 
                                                { flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </section>
            </section>
        </section>
    );
}
 
export default Table;
import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonDark } from "../../components/buttons/Buttons";


const Spain = () => {
  const navigate = useNavigate();

  return (
    <section className="max-w-3xl mx-auto my-10">
      <h1 className=" text-darkblue font-bold text-4xl text-center lg:text-left md:text-6xl font-playfair">
        {" "}
        Spain{" "}
      </h1>
      <section className="text-base sm:text-lg text-color4 text-center lg:text-left font-normal mt-3 ">
        <p className="mt-3">
          <p className="text-darkblue font-medium">Visa Requirements:</p>
          <p>
            Spain is part of the Schengen Area, which means that if you are a
            citizen of a country that is not a member of the Schengen Area, you
            may need a visa to enter Spain.If you need a visa to enter Spain,
            you must apply for it at the Spanish embassy or consulate in your
            country of residence or in a neighboring country.
          </p>
        </p>
        <p className="mt-3">
          <p className="text-darkblue font-medium">Visa Application Process:</p>
          <p>
            The processing time for a Spain visa application can vary depending
            on the type of visa you need and the volume of applications being
            processed by the Spanish embassy or consulate.To apply for a Spain
            visa, you must submit your application to the Spanish embassy or
            consulate in your country of residence or in a neighboring country.
            The application process typically involves filling out an
            application form, paying a fee, providing supporting documents (such
            as a passport, a photograph, and evidence of the purpose of your
            trip), and attending an interview at the embassy or consulate.
          </p>
        </p>
        <p className="mt-3">
          <p className="text-darkblue font-medium">Passport Requirements:</p>
          <p>
            To enter Spain, you must have a valid passport. Your passport must
            be valid for at least three months beyond your intended date of
            departure from the Schengen Area. If you are a citizen of an EU
            country, Switzerland, Norway, Iceland, or Liechtenstein, you can
            enter Spain with a valid national identity card instead of a
            passport.
          </p>
        </p>
        <p className="mt-3">
          <p className="text-darkblue font-medium">
            Passport Application Process:
          </p>
          <p>
            Passport applications for Spanish citizens are handled by the
            Spanish National Police, and processing times can vary depending on
            the demand. If you are a Spanish citizen, you can apply for a
            passport at any National Police station. If you are not a Spanish
            citizen and need to apply for a Spanish passport, you must have
            Spanish citizenship. To obtain Spanish citizenship, you must meet
            certain requirements, such as living in Spain for a certain period
            of time, passing a language and culture test, and meeting other
            eligibility criteria. Once you have obtained Spanish citizenship,
            you can apply for a Spanish passport.
          </p>
        </p>

        <section className="flex justify-center mt-8">
            <ButtonDark handleClick={() => navigate("/visa-application")}>
                Apply For Visa Now
            </ButtonDark>
        </section>
      </section>
    </section>
  );
};

export default Spain;
import { Content } from "./Content"
import { InfoHeader } from "./InfoHeader"
import { SlLocationPin } from "react-icons/sl"
import { useSelector } from "react-redux"

const content = [
    { text1: "Where am I from ?"},
    { text1: "Applying for ?" },
]

export const TripLocation = ({ setRoute,  setCurrentStep }) => {
    const { visaUpdate } = useSelector((state) => state.visa);
    return (
        <section>
            <InfoHeader icon={<SlLocationPin className="text-lg mr-2" />} header={"Trip Location"} 
            editClick={() => {
                setRoute("trip-location");
                setCurrentStep(1)
             }} 
            />
            <section className="grid grid-cols-2 gap-2 mb-6">
                <Content text1={content[0].text1} text2={visaUpdate?.result.from} />
                <Content text1={content[1].text1} text2={visaUpdate?.result.visaType} />
                {/* {
                    content.map((item, id) => (
                        <Content key={id} text1={item.text1} text2={item.text2} />
                    ))
                } */}
                {/* <Content text1={"Applying for ?"} text2={"Student Visa"} /> */}
            </section>
        </section>
    )
}
export const PassportRequirements = ({heading, requirements}) => {
    return (
        <div className="pass-req bg-req_bg rounded-md hidden md:flex flex-col items-start py-[40px] px-[25px] h-full gap-[21px] basis-1/4">
            <h6 className="text-darkblue">{heading}</h6>
            <ul className="flex flex-col gap-[21px]">
                {
                    requirements.map((item, id)  => (
                        <li className="font-normal text-sm text-req_text" key={id}>- {item.longName}.</li>
                    ))
                }
            </ul>
        </div>
    )
}
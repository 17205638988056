import fileIcon from "../../../assets/icons/fileIcon.png";
import { GrFormClose } from "react-icons/gr";

export const RequirementBox = ({item, removeFile}) => {
    const amount = 1000;
    return (
        <section className="req-list bg-gray2 rounded-md py-[16px] px-[24px] gap-[12px] flex flex-row items-center">
            <section className="icon rounded-full p-[10px] bg-gray1">
                <img src={fileIcon} alt="file-icon" width={10} height={10} />
            </section>
            <section className="req">
                <section className="flex items-center space-x-2">
                    <h6 className="font-normal text-sm text-darkblue">
                        {item.name}
                    </h6>
                    <GrFormClose className="text-2xl mt-0.5 cursor-pointer" onClick={() => removeFile(item)} />
                </section>
                <span className="font-normal text-sm text-color2">{ item.size/amount } KB</span>
            </section>
        </section>
    )
}
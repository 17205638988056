import logo from "../../assets/images/revised_logo.png"
import { HiOutlineArrowSmLeft } from "react-icons/hi"
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const Top = () => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    return (
        <section className="w-full flex justify-between mt-4">
            <section>
                <Link to={"/landing"}>
                    <img src={logo} alt="ogavisa-logo" width={"75px"} height={"75px"} />
                </Link>
            </section>
            <section className="w-fit mt-4">
                <HiOutlineArrowSmLeft 
                onClick={goBack}
                className="text-3xl text-darkblue cursor-pointer" />
            </section>
        </section>
    )
}
import * as yup from  "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { CustomSelect } from "../../inputs/CustomSelect";
import "./GeneralInformation.css";
import { CustomInput } from "../../inputs/CustomInput";
import { CustomDatePicker } from "../../inputs/CustomDatePicker";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ButtonDark, ButtonLightBorder } from "../../buttons/Buttons";
import { ErrorMsg } from "../../../alert/ErrorMsg";
import { useDispatch, useSelector } from "react-redux";
import { ErrorToast, SuccessToast, SuccessToast2 } from "../../toast/toasts";
import moment from 'moment';
import {  updateVisaDraft } from "../../../features/unauth-features/visaSlice";
import { useEffect, useState } from "react";
import { addToArray } from "../../../features/unauth-features/generalSlice";

const gender = [
    { value: "F", label: "Female" },
    { value: "M", label: "Male" },
];
const applicationOptions = [
    { value: "no", label: "No" },
    { value: "yes", label: "Yes" },
]

const schema  = yup.object().shape({
    nationality: yup.string().required("nationality is required"),
    gender: yup.string().required("gender is required"),
    surname: yup.string().required("surname is required"),
    first_middle_name: yup.string().required("name is required"),
    dob: yup.date().required("date of birth is required"),
    cob: yup.string().required("country of birth is required"),
    cor: yup.string().required("country of residence is required"),
    passNum: yup.string().required("passport number is required"),
    expire: yup.date().required("passport expiration date is required"),
    requestLocation: yup.string().required("request location is required"),
    applying: yup.string().required("country destination is required "),
    terms: yup.bool().oneOf([true], 'required'),
});

const resetFields = {
    nationality: null,
    gender: null,
    surname: "",
    first_middle_name: "",
    dob: undefined,
    cob: null,
    cor: null,
    passNum: "",
    expire: undefined,
    requestLocation: null,
    applying: null,
    terms: undefined,
};

export const GeneralInformation = ({ setRoute, setSteps, routes, steps, setCurrentStep, currentStep }) => {
    const dispatch = useDispatch();
    const { personsData } = useSelector((state) => state.general);
    const { countriesWithAlias } = useSelector((state) => state.option);
    const {  visaDraft, person } = useSelector((state) => state.visa);
    const [ loading, setLoading ] = useState(false);


    const method = useForm({
        resolver: yupResolver(schema),
        defaultValues: {}
    });
    const { formState: { errors }, register, handleSubmit, reset, setValue } = method;


    const modCountries = countriesWithAlias?.result.map((country) => ({
        value: country.name,
        label: country.name,
    }));
    modCountries.push({value:"", label: ""});

    const handleClick = (data) => {
            setLoading(true);
            const personData = {
                nationality: data.nationality,
                gender: data.gender,
                surname: data.surname,
                firstAndMiddleName: data.first_middle_name,
                dob: moment(data.dob).format("YYYY-MM-DD"),
                countryOfBirth: data.cob,
                countryOfResidence: data.cor,
                passportNumber: data.passNum,
                passportExpires: moment(data.expire).format("YYYY-MM-DD"),
                visaRequestLocation: data.requestLocation,
                liveApplicationVisa: data.applying === 'yes' ? true : false,
            };
            const personsWithoutId = personsData.map(({id, ...rest}) => rest);
                const payload = {
                    id: visaDraft.result._id,
                    data: {
                        persons: [
                            personData,
                            ...personsWithoutId,
                        ]
                    }
                }
            dispatch(updateVisaDraft(payload))
            .unwrap()
            .then((res) => {
                console.log(res);
                setLoading(false);
                SuccessToast('Details uploaded successfully');
                const updatedSteps = steps.map((step) => step.number === currentStep ? {...step, completed: true} : step);
                setSteps(updatedSteps);
                setCurrentStep(4);
                setRoute(routes[3]);
            })
            .catch((err) => {
                setLoading(false);
                ErrorToast(err?.data?.error || "Error occurred");

            })

    }

    const handleAddPerson = (data) => {
        const payload = {
            nationality: data.nationality,
            gender: data.gender,
            surname: data.surname,
            firstAndMiddleName: data.first_middle_name,
            dob: moment(data.dob).format("YYYY-MM-DD"),
            countryOfBirth: data.cob,
            countryOfResidence: data.cor,
            passportNumber: data.passNum,
            passportExpires: moment(data.expire).format("YYYY-MM-DD"),
            visaRequestLocation: data.requestLocation,
            liveApplicationVisa: data.applying === 'yes' ? true : false,
        }
        dispatch(addToArray(payload));
        SuccessToast2('Processing');
        reset(resetFields);
    }

    useEffect(() => {
        if(person !== null) {
            setValue("nationality", person?.nationality) 
            setValue("gender", person?.gender)
            setValue("surname", person?.surname)
            setValue("first_middle_name", person?.firstAndMiddleName)
            setValue("dob", person?.dob)
            setValue("cob", person?.countryOfBirth)
            setValue("cor", person?.countryOfBirth)
            setValue("passNum", person?.passportNumber)
            setValue("expire", person?.passportExpires)
            setValue("requestLocation", person?.visaRequestLocation)
            setValue("applying", person?.liveApplicationVisa === true ? "yes" : "no");

        }
    }, [person, setValue])




    return (
        <>
            <section  className={`w-full mt-5 max-w-[1000px] mx-auto xs:shadow-[0px_2px_12px_rgba(0,0,0,0.12)] mb-5 sm:px-[6%] py-[7%] rounded-md`}>
                <section className="information">
                    <section className="">
                        <CustomSelect
                            method={method} 
                            name={'nationality'} 
                            label={'What is your nationality?'}
                            placeholder={'Nigeria (NG)'}
                            options={modCountries}
                        />
                    </section>
                    <section className="">
                        <CustomSelect
                            method={method}
                            name={'gender'} 
                            label={'Gender'} 
                            placeholder={'Select'}
                            options={gender} 
                        />
                    </section>
                </section>

                <section className="information">
                    <section>
                        <CustomInput
                            method={method}
                            name={"surname"}
                            label={"Surname"}
                            placeholder={"Type here..."}
                            type={'text'}
                        />
                    </section>
                    <section>
                        <CustomInput
                            method={method}
                            name={"first_middle_name"}
                            label={"First and middle name"}
                            placeholder={"Type here..."}
                            type={'text'}
                        />
                    </section>
                </section>

                <section className="information">
                    <section>
                        <CustomDatePicker 
                            name={"dob"}
                            label={"Date of birth"}
                            method={method}
                        />
                    </section>
                    <section>
                        <CustomSelect
                            method={method}
                            name={'cob'} 
                            label={'Your country of birth'} 
                            placeholder={'Nigeria (NG)'}
                            options={modCountries} 
                        />
                    </section>
                </section>

                <section className="information">
                    <section>
                        <CustomSelect
                            method={method}
                            name={'cor'} 
                            label={'Your country of residence'} 
                            placeholder={'Nigeria (NG)'}
                            options={modCountries} 
                        />
                    </section>
                    <section>
                        <CustomInput
                            method={method}
                            name={"passNum"}
                            label={"Passport number"}
                            placeholder={"Type here..."}
                            type={'text'}
                        />
                    </section>
                </section>

                <section className="information">
                    <section>
                        <CustomDatePicker 
                            name={"expire"}
                            label={"When does your passport expires"}
                            method={method}
                        />
                    </section>
                    <section>
                        <CustomSelect
                            method={method}
                            name={'requestLocation'} 
                            label={`Visa request location`}
                            icon={<AiOutlineExclamationCircle className="text-darkblue text-lg ml-2" />} 
                            placeholder={'Select'}
                            options={modCountries} 
                        />
                    </section>
                </section>

                <section>
                    <section>
                        <CustomSelect
                            method={method}
                            name={'applying'} 
                            label={'Do you live in same country where you are applying for the visa?'} 
                            defaultValue={"Yes"}
                            options={applicationOptions} 
                        />
                    </section>
                </section>


                <section>
                    <section className="flex space-x-2 mt-5">
                        <section>
                            <input
                            {...register(`${"terms"}`)}
                            className="focus:outline-none" 
                            type={'checkbox'}
                            name={"terms"}
                            />
                        </section>
                        <p 
                        className="text-sm sm:text-base font-medium text-[#797A7C]"
                        > 
                            I have read <Link to={'/terms'} target="_blank" className="text-pending">Terms of service </Link> &  
                            <Link to={'/privacy'} target="_blank" className="text-pending"> Privacy and Policy</Link> 
                        </p>
                    </section>
                    { errors["terms"] && <ErrorMsg msg={errors["terms"].message} /> }
                </section>


                <section className="mt-6 flex flex-wrap space-x-3">
                    <section className="flex w-fit">
                        <ButtonLightBorder 
                        handleClick={handleSubmit(handleAddPerson)}
                        disabled={loading}
                        >
                            Add Another Person
                        </ButtonLightBorder>
                    </section>
                    <section className=" flex w-fit">
                        <ButtonDark 
                        handleClick={handleSubmit(handleClick)}
                        disabled={loading}
                        >
                            { loading ? <p className={`${loading && "loading-dot"} pr-2`}> Loading  </p> : 'Continue'}
                        </ButtonDark>
                    </section>  
                </section>

            </section>
        </>
    );
}
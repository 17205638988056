import * as yup from  "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { CustomSelect } from "../../inputs/CustomSelect";
import "./TripLocation.css";
import { ButtonDark } from "../../buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { addToTripLocation } from "../../../features/unauth-features/generalSlice";
import { useEffect, useState } from "react";
import { Modal } from "./Modal/Modal";


const schema  = yup.object().shape({
    location: yup.string().required("nationality is required"),
    visaType: yup.string().required("visa type is required"),
});

export const TripLocation = ({ setRoute, setSteps, routes, steps, setCurrentStep, currentStep }) => {
    const dispatch = useDispatch();
    const { countries, visaTypes } = useSelector((state) => state.option);
    const { locationAndDestination } = useSelector((state) => state.general);
    const {  visaDraft } = useSelector((state) => state.visa);
    const [ loading, setLoading ] = useState(false);
    const [ modal, setModal ] = useState(false);

    const method = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            location: visaDraft.result ? visaTypes?.result?.destination : "",
            visaType: visaTypes.result ? visaTypes?.result?.visaType : ""
        }
    });
    const { handleSubmit, reset } = method;

    useEffect(() => {
        const draftFromStorage = JSON.parse(localStorage.getItem('visaDraft'));
        if (draftFromStorage) {
            const savedValues = {
                location:  draftFromStorage.destination,
                visaType: draftFromStorage.visaType,
            }
            reset(savedValues);   
        }
    }, [reset])


    const modCountries = countries?.result.map((country) => ({
        value: country.name,
        label: country.name,
    }));

    const modVisaTypes = visaTypes?.result.map((visa) => ({
        value: visa.name,
        label: visa.name,
    }))

    const handleClick = (data) => {
        setLoading(true);
        const payload = {
            from: data.location,
            visaType: data.visaType
        }
        if (locationAndDestination.length !== 0) {
            dispatch(addToTripLocation(payload));
            setLoading(false);
            const updatedSteps = steps.map((step) => step.number === currentStep ? {...step, completed: true} : step);
            setSteps(updatedSteps);
            setCurrentStep(2);
            setRoute(routes[1]);
        } else {
            setLoading(false)
            setModal(true);
        }

    }

    return (
        <>
           { modal && 
                <div 
                className={`fixed top-0 bottom-0 left-0 right-0 bg-color3 transition-all ease-linear 
                duration-100 overflow-hidden z-30`}></div>
            }
            <section className="w-full max-w-[1000px] mx-auto xs:shadow-[0px_2px_12px_rgba(0,0,0,0.12)] sm:px-[6%] py-[8%] rounded-md mb-52">
                <section className="location">
                    <section className="">
                        <CustomSelect
                        method={method} 
                        name={'location'} 
                        label={'Where am I from ?'}
                        placeholder={'Nigeria'}
                        options={modCountries}
                        />
                    </section>

                    <section className="">
                            <CustomSelect
                            method={method}
                            name={'visaType'} 
                            label={'Applying for ?'} 
                            placeholder={'Select visa type'}
                            options={modVisaTypes} 
                            />
                    </section>
                </section>
                <section className="mt-6 flex w-fit" onClick={handleSubmit(handleClick)}>
                        <ButtonDark>
                            { loading ? <p className={`${loading && "loading-dot"} pr-2`}> Loading  </p> : 'Continue'}
                        </ButtonDark>
                </section>
            { modal && <Modal setModal={setModal} />}

            </section>
        </>
    )
}
import React from "react";
import { useSelector } from "react-redux";

const FileDetails = ({ heading, button }) => {
    const { passportUpdate } = useSelector((state) => state.passport);
  return (
    <section className="">
      <section className="heading flex justify-between">
        <section className="title flex gap-x-1 text-2xl text-pending">
          {heading}
        </section>
        <section className="edit">{button}</section>
      </section>
      <ul className="my-[30px] grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-[30px]">
        <li>
            <p className="text-color3 font-normal"> No of Files </p>
                <p className="text-black mt-1.5 font-medium break-words"><strong>{passportUpdate?.result?.documentFile.length}</strong></p>
        </li>
      </ul>
        { passportUpdate?.result?.documentFile.map((item, id) => (
            <ul key={id} className="my-[30px] grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-[30px]">
                <li>
                    <p className="text-color3 font-normal"> File Name </p>
                    <p className="text-black mt-1.5 font-medium break-words"><strong>{item.fileName}</strong></p>
                </li>
                <li>
                    <p className="text-color3 font-normal"> File Type </p>
                    <p className="text-black mt-1.5 font-medium break-words"><strong>{item.mimeType}</strong></p>
                </li>
                <li>
                    <p className="text-color3 font-normal"> File Size</p>
                    <p className="text-black mt-1.5 font-medium break-words"><strong>{item.size} bytes </strong></p>
                </li>
            </ul>
        ))}
    </section>
  );
};

export default FileDetails;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { setActiveTab } from "../../features/auth-features/userSlice";

export const Tab = ({ tabs }) => {
    const { activeTab } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const location = useLocation();
    const [ searchParams ] = useSearchParams();
    const param = searchParams.get('tab');
    const dispatch = useDispatch();

    const onTab = (alias) => {
        dispatch(setActiveTab());
        navigate(`${location.pathname}?tab=${alias}`);
    }

    useEffect(() => {
        if (tabs.some((tab) => tab.alias === param)) {
            dispatch(setActiveTab(param));
        } else {
            dispatch(setActiveTab(tabs[0].alias));
        }
    }, [param]);

    return (
        <section className="text-darkblue font-medium text-base flex items-center space-x-4 mb-5 px-[6%] transition-all duration-200 ease-in-out">
            { tabs.map((tab) => (
                <p
                onClick={() => onTab(tab.alias)}
                key={tab.alias} 
                className={`py-2 px-3 cursor-pointer ${activeTab === tab.alias ? 'bg-tab rounded-md' : ''}`}> { tab.name } </p>
            ))}
        </section>
    )
}
import { useSelector } from "react-redux";
import Status from "../status/Status";
import "./ApplicationStatus.css";
import { PaginatedTable } from "../table/PaginatedTable";
import { createColumnHelper } from "@tanstack/react-table";
import { OnlyDate } from "../excerpts/OnlyDate";
import LoadingSpinnerTables from "../loader/LoadingSpinnerTables";
import { CgProfile } from "react-icons/cg";

export const ApplicationStatus = () => {
    const { userApplication } = useSelector((state) => state.user);

    
const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor(row => `${row.fullName}, ${row.email}`, {
        id: "profile",
        cell: (info) => (
            <section className={`flex items-center`}>
                <section className="rounded-[200px] w-[32px] h-[32px]">
                    <CgProfile className="text-3xl text-darkblue" />
                </section>
                <section className={`ml-2`}>
                    <p className="text-sm text-gray-900 font-medium">{ info.getValue().split(",")[0] }</p>
                    <p className="text-sm font-normal text-gray-500">{ info.getValue().split(",")[1] }</p>
                </section>
            </section>
        ),
        header: () => <span>Name</span>,
    }),
    columnHelper.accessor('createdAt', {
        cell: (info) => (
            <section className={``}>
                <p className="text-sm font-normal text-color6"> <OnlyDate date={info.getValue()} /> </p>
            </section>
            ),
        header: () => <span>Date</span>,
    }),
    columnHelper.accessor('type', {
        cell: (info) => (
            <section className={``}>
                <p className="text-sm font-normal text-color6"> {info.getValue()} </p>
            </section>
        ),
        header: () => <span>Application Type</span>,
    }),
    columnHelper.accessor('status', {
        cell: (info) => <Status value={info.getValue()} />,
        header: () => <span>Status</span>,
    }),
    columnHelper.accessor('option', {
        cell: (info) => (
            <section className={``}>
            <p className="text-pending font-medium text-sm"> {info.getValue()} </p>
        </section>
    ),
        header: () => <span>  </span>,
    }),
];

    return (
    <section className="transition-all shadow-[0px_0_2px_rgba(0,0,0,0.1)] px-[6%] ">
        { userApplication.status === "loading" ?
            <section className="h-[200px] flex justify-center items-center">
                <LoadingSpinnerTables />
            </section>
            : 
            <PaginatedTable data={userApplication.result} columns={columns} filter={true} paginate={true}/>
        }
    </section>
    )
};
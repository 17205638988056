import { CustomInput } from "../inputs/CustomInput";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { CgProfile } from "react-icons/cg";


const schema  = yup.object().shape({
    name: yup.string().required(),
    address: yup.string().required(),
    email: yup.string().email().required(),
    number: yup.string().required(),
});

export const Profile = () => {
    const { userInfo } = useSelector((state) => state.user)
    const method = useForm({
        resolver: yupResolver(schema)
    });
    const { reset } = method;

    
    useEffect(() => {
        const resetValues = {
            name: userInfo?.result?.fullName,
            address:  userInfo?.result?.address,
            email: userInfo?.result?.email,
            number: userInfo?.result?.phoneNumber,
        };
        reset(resetValues);
    }, [userInfo, reset])
    
    return (
        <section className="shadow-[0px_0_2px_rgba(0,0,0,0.1)] px-[6%] pt-10 pb-20 transition-all ease-in-out">
            <section className="flex flex-wrap space-y-5 justify-between">
                <section className="flex items-center flex-wrap space-x-3.5">
                    <CgProfile className="text-3xl text-darkblue" />
                    { userInfo.status === "loading" ?
                        <Skeleton className="my-auto" width={200} height={30} />
                        :
                        <section className="mt-2.5">
                            <p className="text-base text-gray-900 font-medium flex flex-wrap"> { userInfo.result?.fullName } </p>
                            <p className="text-base font-normal text-gray-500 flex flex-wrap"> { userInfo.result?.email } </p>
                        </section>

                    }
                </section>
            </section>
            <section className="mt-12 max-w-[800px]">
                <section>
                { userInfo.status === "loading" 
                    ?
                    <Skeleton className="mb-4" width={800} height={50}  />
                    : 
                    <CustomInput name={"name"} label={"Full name"} method={method} disabled/>
                }
                { userInfo.status === "loading" 
                    ?
                    <Skeleton className="mb-4" width={800} height={50}  />
                    : 
                    <CustomInput name={"email"} label={"Email"}  method={method} disabled/>
                }
                { userInfo.status === "loading" 
                    ?
                    <Skeleton className="mb-4" width={800} height={50}  />
                    :
                    <CustomInput name={"number"} label={"Phone number"}  method={method} disabled/>
                }
                { userInfo.status === "loading" 
                    ?
                    <Skeleton className="mb-4" width={800} height={50}  />
                    : 
                    <CustomInput name={"address"} label={"Address"} method={method} disabled/>
                }
                </section>
            </section>
        </section>
    )
}
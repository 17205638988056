import React from "react";

const ApplicationSteps = () => {
  return (
    <section className="px-[6%] my-4" id="steps">
      <h1 className=" text-darkblue font-bold text-3xl text-center lg:text-left md:text-5xl font-playfair">
        Application Steps
      </h1>
      <p className="text-base sm:text-lg text-color3 text-center lg:text-left font-normal mt-5">
        Ogavisa is an online visa processing company that helps travelers obtain
        visas and other travel documents in a quick and convenient manner.
        Here's how Ogavisa typically works:
      </p>
      <ol className="text-base sm:text-lg text-color4 text-center lg:text-left font-normal mt-5 ">
        <li className="mt-5">
          <h2 className="text-darkblue font-medium">
            Visit the Ogavisa website:
          </h2>
          <p>
            First, go to the Ogavisa website and select the type of visa or
            travel document you need.
          </p>
        </li>
        <li className="mt-5">
          <h2 className="text-darkblue font-medium">
            Fill out the application form:
          </h2>
          <p>
            You'll need to provide personal information such as your name,
            address, passport details, and travel plans.
          </p>
        </li>
        <li className="mt-5">
          <h2 className="text-darkblue font-medium">
            Upload supporting documents:
          </h2>
          <p>
            Depending on the type of visa you need, you may need to upload
            additional documents such as a passport photo, proof of e
          </p>
        </li>
        <li className="mt-5">
          <h2 className="text-darkblue font-medium">Pay the processing fee:</h2>
          <p>
            Once you've completed the application form and uploaded your
            documents, you'll need to pay the processing fee. The fee varies
            depending on the type of visa and how quickly you need it.
          </p>
        </li>
        <li className="mt-5">
          <h2 className="text-darkblue font-medium">Wait for processing:</h2>
          <p>
            After you've submitted your application and paid the fee, Ogavisa
            will begin processing your application. Processing times vary
            depending on the type of visa and other factors such as the country
            you're traveling to.
          </p>
        </li>
        <li className="mt-5">
          <h2 className="text-darkblue font-medium">Receive your visa:</h2>
          <p>
            Once your application has been approved, Ogavisa will send you the
            electronic visa via email or have you come pick it up. You'll need
            to print out the visa and present it to immigration officials when
            you arrive in the country
          </p>
        </li>
      </ol>
      <p className="text-base sm:text-lg text-color3 text-center lg:text-left font-normal mt-5">
        Overall, Ogavisa can make the visa application process faster and more
        convenient than applying through a traditional embassy or consulate.
        However, it's important to note that Ogavisa is not affiliated with any
        government or embassy, and they cannot guarantee that your visa
        application will be approved.
      </p>
    </section>
  );
};

export default ApplicationSteps;

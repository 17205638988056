import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { ButtonLargeDark } from "../buttons/Buttons";
import { CustomInput } from "../inputs/CustomInput";
import { Heading } from "./Heading";
import { useDispatch } from "react-redux";
import { requestOTP } from "../../features/unauth-features/loginSlice";
import { useState } from "react";
import { ErrorMsg } from "../../alert/ErrorMsg";


const schema  = yup.object().shape({
    email: yup.string().email().required(),
});

export const WelcomeBack = ({ setStep }) => {
    const dispatch = useDispatch();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState("");
    const method = useForm({
        resolver: yupResolver(schema)
    })
    const { handleSubmit } = method;
    
    const handleClick = (data) => {
        setLoading(true)
        const payload = {
            email: data.email,
        }
        dispatch(requestOTP(payload))
            .unwrap()
            .then((res) => {
                setLoading(false);
                setStep("verify");
            })
            .catch((err) => {
                setLoading(false);
                setError(err?.data?.error || err?.data?.message || err?.data || "Error occurred, please try again");
            })
    }

    return (
        <>
            <Heading heading={"Welcome back"} text={"Log in to Check Application Status"} />
            <section className="max-w-[360px] mx-auto">
                <CustomInput label={"Email"} name={"email"} placeholder={"enter registered email"} method={method} />
                <section className="mt-4">
                    <ButtonLargeDark handleClick={handleSubmit(handleClick)}>
                        {loading ? <p className={`${loading && "loading-dot"} pr-2`}> Loading  </p> : 'Request OTP'}
                    </ButtonLargeDark>
                </section>
                <section className="flex justify-center mt-2">
                    { error && <ErrorMsg msg={error} /> }
                </section>
            </section>
        </>
    )
}
import { useEffect, useState } from "react";
import { TripLocation } from "../../components/visa/trip-location/TripLocation";
import { Step } from "../../components/visa/Steps";
import { TabHeading } from "../../components/tabHeading/TabHeading";
import { TripInformation } from "../../components/visa/trip-information/TripInformation";
import { GeneralInformation } from "../../components/visa/general-information/GeneralInformation";
import { VisaTripInformation } from "../../components/visa/visa-trip-information/VisaTripInformation";
import { BsArrowLeft } from "react-icons/bs";
import PersonsTable from "../../components/visa/general-information/PersonsTable";
import { useLocation } from "react-router-dom";

const VisaServices = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const routes = ["trip-location", "trip-information", "general-information", "visa-trip-information" ]
  const [ route, setRoute ] = useState(routes[0]);
  // for general information sub-route
  const updateFromStorage = JSON.parse(localStorage.getItem('visaUpdate'));
  const location = useLocation();

  const [steps, setSteps] = useState([
    { number: 1, completed: false, name: "Trip-Location" },
    { number: 2, completed: false, name: "Trip-Information" },
    { number: 3, completed: false,  name: "General Information" },
  ]);

  const handlePrevious = () => {
      setCurrentStep(currentStep => currentStep - 1);
      let routeNumber = routes.indexOf(route); // gets index number of current route
      const updatedSteps = steps.map((step) => step.number === currentStep ? {...step, completed: false} : step); // gets current step and updates it
      setSteps(updatedSteps);
      setRoute(routes[routeNumber - 1]); // goes back to the previous route
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
    <section id="visa-application"  className="px-[6%] w-full">
      <TabHeading
      heading={"Visa Services"}
      text={"Apply for visas hassle-free with Ogavisa."}
      />
      { route !== routes[0] &&
        <p 
        onClick={handlePrevious} 
        className="text-color10 font-normal text-base mt-3.5 flex items-center cursor-pointer"
        > 
          <BsArrowLeft className="mr-2 font-extrabold" /> Previous 
        </p> 
      }
      <section className="grid grid-rows-3 gap-4 md:flex md:flex-wrap justify-center md:space-x-4 mt-8 mb-12">
        { steps.map((step, id) => (
          <Step
          key={id}
          name={step.name}
          number={step.number}
          color={step.completed === true || currentStep === step.number ? 'black' : 'rgba(0, 0, 0, 0.5)'}
          borderColor={step.completed === true || currentStep === step.number ? '#48C4EF' : '#D9D9D9'}
          bgColor={currentStep === step.number ? '' : step.completed === true ? '#48C4EF' :'#D9D9D9' }
          show={step.number !== 3 ? true : false}
          />
        ))

        }
      </section>
      
      { route === routes[0] && 
        <TripLocation 
        setRoute={setRoute} 
        routes={routes} 
        setSteps={setSteps} 
        steps={steps} 
        setCurrentStep={setCurrentStep} 
        currentStep={currentStep}  
      /> 
      }
      
      { route === routes[1] &&  
        <TripInformation 
        setRoute={setRoute} 
        routes={routes} 
        setSteps={setSteps} 
        steps={steps} 
        setCurrentStep={setCurrentStep} 
        currentStep={currentStep} 
        /> 
      }
      
      { route === routes[2] &&
        <section className="flex flex-wrap lg:justify-between">
          <section className={`w-full ${updateFromStorage?.persons ? "lg:w-[55%]" : ""}`}>
            <GeneralInformation
            setRoute={setRoute} 
            routes={routes} 
            setSteps={setSteps} 
            steps={steps} 
            setCurrentStep={setCurrentStep} 
            currentStep={currentStep}
            /> 
          </section>
          { updateFromStorage?.persons &&
            <section className="w-full lg:w-[40%]">
              <PersonsTable />
            </section>
          }
        </section> 
      }
      { route === routes[3] &&
       <VisaTripInformation  setRoute={setRoute}  setCurrentStep={setCurrentStep} /> 
      }

    </section>
  );
}
 
export default VisaServices;
  
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    personsData: JSON.parse(localStorage.getItem('persons')) || [],
    locationAndDestination: [],
    tripLocation: [],
    country: null,
};


const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        getCountry: (state, action) =>  {
            state.country = action.payload;
        },
        addToArray: (state, action) => {
            state.personsData.push(action.payload);
            localStorage.setItem("persons", JSON.stringify(state.personsData));
        },
        addToLocationAndDestination(state, action) {
            state.locationAndDestination = action.payload;
        },
        addToTripLocation(state, action) {
            state.tripLocation = action.payload;
        },
        clearStorageVisa: () => {
            localStorage.removeItem("visaDraft");
            localStorage.removeItem("visaUpdate");
            localStorage.removeItem("persons");
        },
        clearStoragePassport: () => {
            localStorage.removeItem("passportDraft");
            localStorage.removeItem("passportUpdate");
        },
    }
});

export const {
    getCountry,
    addToArray,
    addToLocationAndDestination,
    addToTripLocation,
    clearStorageVisa,
    clearStoragePassport,
  } = generalSlice.actions;
export default generalSlice.reducer;
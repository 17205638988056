import { Link } from "react-router-dom"

export const Countries = ({data}) => {
    return (
            <section className="mt-10 hidden md:flex">
                <section 
                className="grid grid-countries  lg:grid-rows-2 lg:grid-flow-col gap-4 lg:h-[600px] w-full">
                    { data.map((info, id) => (
                        
                        <div
                        key={id}
                        className={`w-full h-full flex items-end 
                        bg-no-repeat bg-cover bg-center cursor-pointer hover:scale-110 transition-all duration-300 ease-in-out`}
                        style={{backgroundImage: `url(${info.img})`}}
                        >
                            <Link to={info.link} className=" h-full w-full  flex items-end ">
                                <p 
                                className="ml-[9%] mb-[9%] text-white font-semibold text-xl md:text-2xl flex items-center"
                                >
                                    {info.name} <img className="ml-2" src={info.icon} width={35} height={35} alt={info.name} /> 
                                </p>
                            </Link>
                        </div>
                    ))}
                </section>
            </section>
    )
}
import { CiSearch } from "react-icons/ci";

const Search = ({ label, ...rest }) => {

    return (
        <section className="w-full xs:w-[350px]">
            <div className="relative border border-gray-300 p-0.5 rounded-lg w-full outline-0">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <CiSearch className="text-xl text-gray-500 font-bold"/>
                </span>
                <input 
                className="py-2 text-base text-gray-500 pl-8 ring-0 outline-0 w-full" 
                placeholder="Search"
                name="search"
                {...rest}
                />
            </div>
        </section>
    );
}
 
export default Search;
import { useState } from "react";
import ReactCodeInput from "react-code-input";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMsg } from "../../alert/ErrorMsg";
import { requestOTP, userLogin } from "../../features/unauth-features/loginSlice";
import { ButtonLargeDark } from "../buttons/Buttons";
import { labelStyling } from "../inputs/InputStyles";
import { Heading } from "./Heading";
import "./VerifyEmail.css";


export const VerifyEmail = ({ setStep }) => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [ error, setError ] = useState("");
  const dispatch = useDispatch();
  const [valid, setValid] = useState(true);
  const { otpInfo } = useSelector((state) => state.login);
  
  const onVerifyOTP= () => {
    if (code.length < 6) {
        setValid(false);
    } else {
        setLoading(true)
        const payload = {
            email: otpInfo?.result?.query?.email,
            otp: code.toString(),
        }
        dispatch(userLogin(payload))
            .unwrap()
            .then((res) => {
                setLoading(false);
                window.location.href = '/';
            })
            .catch((err) => {
                setValid(false);
                setLoading(false);
                setError(err?.data?.error || err?.data?.message || err?.data || "Unable to verify email")
            })
    }
}
    const resendOTP = () => {
        setLoading(true);
        const payload = {
            email: otpInfo?.result?.query?.email,
        }
        dispatch(requestOTP(payload))
            .unwrap()
            .then((res) => {
                setLoading(false);
            })
            .catch((err) => {
                setError(err?.data?.error || err?.data?.message || err?.data || "Error occurred in sending otp")
            })
    }

    const props = {
        className: 'text-xl font-medium w-full space-x-1 space-y-1',
        inputStyle: {
            outline: '0',
            textAlign: 'center',
            width: '55px',
            height: '55px',
            // margin: '4px',
            color: '#000000',
            border: '1px solid rgba(0, 0, 0, 0.25)',
            borderRadius: '6px',
        },
        inputStyleInvalid: {
            outline: '0',
            textAlign: 'center',
            width: '55px',
            height: '55px',
            // margin: '4px',
            color: '#000000',
            border: '1px solid red',
            borderRadius: '6px',
        }
    }

    return (
        <>
            <Heading 
            heading={"Verify Email Address"} 
            text={`Please enter the OTP sent to your registered email address `}
            email={otpInfo?.result?.query?.email} 
            />
            <section className="max-w-[360px] mx-auto mt-16">
                <section>
                    <label style={labelStyling}> OTP </label>
                    <ReactCodeInput 
                    type={"number"} 
                    fields={6} 
                    {...props}
                    onChange={(value) => {
                      setCode(value);
                      setValid(true);
                    }} 
                    value={code}
                    isValid={valid}
                    />
                    <button className="text-[12px] text-darkblue font-semibold text-left my-4 cursor-pointer" onClick={resendOTP}> Resend OTP </button>
                </section>
                <section className="mt-4" onClick={onVerifyOTP}>
                    <ButtonLargeDark>
                    { loading ? <p className={`${loading && "loading-dot"} pr-2`}> Loading  </p> : 'Verify'}
                    </ButtonLargeDark>
                </section>
                <section className="text-center mt-4">
                    { error && <ErrorMsg msg={error} /> }
                    { !valid && <ErrorMsg msg={"Invalid OTP"} />  }
                </section>
            </section>
        </>
    )
}
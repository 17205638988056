import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonDark } from '../../components/buttons/Buttons';

const Egypt = () => {
  const navigate = useNavigate();
  return (
    <section className='max-w-3xl mx-auto my-4'>
      <h1 className=" text-darkblue font-bold text-4xl text-center lg:text-left md:text-6xl font-playfair"> Egypt </h1>
      <section className='text-base sm:text-lg text-color4 text-center lg:text-left font-normal mt-3 '>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Visa Requirements:
          </p>
          <p>
            Foreign visitors to Egypt generally require a visa to enter the country. 
            The visa requirements can vary depending on the visitor's nationality, the purpose of their visit, and the length of their stay.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Visa Application Process:
          </p>
          <p>
            Visitors to Egypt typically need a visa to enter the country, although some nationalities may be exempt. 
            Visitors can obtain a visa on arrival at Egyptian airports and ports of entry, or apply for a visa in advance from an Egyptian embassy or consulate in their home country.
            The visa on arrival process is relatively straightforward, with visitors filling out an application form and paying the visa fee in cash (US dollars, Euros, or British pounds are usually accepted). 
            The fee varies depending on the type of visa and the length of stay, but generally ranges from $25 to $60 USD.
            Visitors can also apply for an e-visa before their trip through the Egyptian government's online portal. 
            This is recommended for travelers who want to avoid potential long lines and wait times at the airport.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Passport Requirements:
          </p>
          <p>
            Egyptian citizens who wish to travel internationally need a valid passport. 
            The passport must be machine-readable and comply with international standards for biometric data such as a digital photograph and fingerprints.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
              Passport Application Process:
          </p>
          <p>
            The process for applying for a passport in Egypt involves the following steps:

            Gather the Required Documents: Applicants need to gather the necessary documents including a national ID card, a birth certificate, and proof of address.

            Complete the Passport Application Form: The passport application form can be obtained from a local passport office or downloaded from the Ministry of Interior's website. The form must be completed in Arabic and signed by the applicant.

            Submit the Application: The completed application form along with the required documents must be submitted to a local passport office.

            Pay the Application Fee: The application fee varies depending on the type of passport and the speed of processing. Payment can be made in cash or through a bank deposit.

            Attend an Interview: Applicants may be required to attend an interview at the passport office to verify their identity and provide additional information.

            Wait for Processing: The processing time for a standard passport application is usually around two weeks. For an additional fee, expedited processing is available which can shorten the processing time to 1-3 days.

            Collect the Passport: Once the passport is ready, the applicant will be notified to collect it from the passport office.

            It's important to note that the passport application process may differ slightly depending on the applicant's age, whether they are applying for a new passport or a renewal, and other factors. It's recommended to check with the nearest passport office for specific instructions on how to apply for a passport in Egypt.
          </p>
        </p>

        <section className="flex justify-center mt-8">
          <ButtonDark handleClick={() => navigate("/visa-application")}>
            Apply For Visa Now
          </ButtonDark>
        </section>
          

      </section>




    </section>
  );
}

export default Egypt
export const InfoHeader = ({ icon, header, editClick }) => {
    return (
        <section className="flex items-center justify-between max-w-[450px] mb-4">
            <h3 className="text-pending font-medium text-xl flex items-center"> {icon} {header} </h3>
            <button 
            className="font-medium text-[12px] text-color3 bg-color7 rounded-sm w-[42px] h-[24px] flex text-center justify-center items-center"
            onClick={editClick}
            > 
                Edit 
            </button>
        </section>
    )
}
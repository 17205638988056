import React from 'react'
import { useNavigate } from 'react-router-dom';
import { ButtonDark } from '../../components/buttons/Buttons';
const Canada = () => {
  const navigate = useNavigate();
  return (
    <section className='max-w-3xl mx-auto my-4'>
      <h1 className=" text-darkblue font-bold text-4xl text-center lg:text-left md:text-6xl font-playfair"> Canada </h1>
      <section className='text-base sm:text-lg text-color4 text-center lg:text-left font-normal mt-3 '>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Visa Requirements:
          </p>
          <p>
            Visitors from most countries, including the United States, need a visa to travel to Canada. 
            However, visitors from some countries, such as citizens of certain European countries, do not need a visa for short-term stays of up to 90 days. 
            Visitors who are eligible for visa-free travel to Canada need to apply for an Electronic Travel Authorization (eTA) before traveling to Canada.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Visa Application Process:
          </p>
          <p>
            If you need a visa to travel to Canada, you can apply online or at a Canadian visa application center in your home country. 
            You will need to fill out a visa application form and provide supporting documents, such as a passport, travel itinerary, and proof of sufficient funds. 
            The visa processing time can vary, but it usually takes around 20 to 30 days.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Passport Requirements:
          </p>
          <p>
            Visitors to Canada need a passport that is valid for at least six months beyond the planned departure date. Some countries may also require a visa or other travel documents.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
              Passport Application Process:
          </p>
          <p>
            If you need to apply for a passport, you can do so at your local passport office or online. 
            You will need to provide proof of identity, such as a birth certificate or driver's license, and two passport-sized photos. 
            The processing time for a passport application can vary, but it usually takes around 20 working days.
          </p>
        </p>
 
        <section className="flex justify-center mt-8">
          <ButtonDark handleClick={() => navigate("/visa-application")}>
            Apply For Visa Now
          </ButtonDark>
        </section>

      </section>




    </section>
  );
}

export default Canada
import { DEV_URL, REDIRECT_DEV_URL, REDIRECT_STAGING_URL, STAGING_URL } from "./constants";


export const redirector = () => {
    const origin = window.location.origin;
    console.log(origin);
    if (origin === DEV_URL) {
      return REDIRECT_DEV_URL;
    }
  
    if (origin === STAGING_URL) {
      return REDIRECT_STAGING_URL;
    }
  
    // if (origin === PROD_URL) {
    //   return REDIRECT_PROD_URL;
    // }
};
  
  export const status403 = () => {
    window.location.reload();
    localStorage.removeItem('ogaVisaToken');
};
  
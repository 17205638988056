const styling = {
    transition: "all 200ms ease-out",
}



export const ButtonDark = ({ children, handleClick, ...rest }) => {
    return (
        <>
            <button 
            className="text-[15px]  sm:text-lg lg:text-xl py-3 px-3 border-none  outline-0 bg-darkblue font-medium rounded-md text-white"
            style={styling}
            onClick={handleClick}
            {...rest}
            >
                { children }
            </button>
        </>
    )
}

export const ButtonLargeDark = ({ children, handleClick, ...rest }) => {
    return (
        <>
            <button
            onClick={handleClick}
            className="text-[15px] sm:text-lg lg:text-xl py-3 w-full border-none outline-0 bg-darkblue font-medium rounded-md text-white"
            style={styling}
            {...rest}
            >
                { children }
            </button>
        </>
    )
}


export const ButtonDarkBorder = ({ children, handleClick, ...rest }) => {
    return (
        <>
            <button 
            className="text-[15px] sm:text-lg lg:text-xl py-3 px-3 border-darkblue border-[1px] text-darkblue outline-0 font-bold rounded-md"
            style={styling}
            onClick={handleClick}
            {...rest}
            >
                { children }
            </button>
        </>
    )
}

export const ButtonLightBorder = ({ children, isHome, handleClick, ...rest }) => {
    return (
        <>
            <button 
            className={`text-[15px] sm:text-lg lg:text-xl py-1 sm:py-1.5 px-5 sm:px-8 lg:py-2 lg:px-10 bg-white 
            hover:bg-pending hover:border-pending transition-all ease-in-out ${isHome ? 'border-white' : 'border-color1'} border-[1px] 
            text-darkblue outline-0 font-semibold rounded-md`}
            style={styling}
            onClick={handleClick}
            {...rest}
            >
                { children }
            </button>
        </>
    )
}

export const ButtonSmallLightBorder = ({ children, isHome, handleClick, ...rest }) => {
    return (
        <>
            <button 
            className={`text-[15px] sm:text-lg lg:text-xl py-1 sm:py-1.5 px-3 sm:px-4 lg:py-2 lg:px-6 bg-white 
            hover:bg-pending hover:border-pending transition-all ease-in-out ${isHome ? 'border-white' : 'border-color1'} border-[1px] 
            text-darkblue outline-0 font-medium rounded-md`}
            style={styling}
            onClick={handleClick}
            {...rest}
            >
                { children }
            </button>
        </>
    )
}
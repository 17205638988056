import { Navigate } from "react-router-dom";
import { HomeLayout } from "./HomeLayout";

export const ProtectedLayout = ({ children, token }) => {
    
    if (token) {
        return (
                <HomeLayout>
                    { children }
                </HomeLayout>
        )       
    } else {
        return <Navigate to={"/landing"} />
    }
};
import { BsCheckLg } from "react-icons/bs";

export const ConfirmationBox = ({ heading, text }) => {
  return (
    <>
      <section className="bg-darkblue w-full flex sm:flex-row flex-col gap-5 rounded-md py-[50px] px-[30px]">
        <section className="icon sm:w-[50px] h-[50px] flex bg-white rounded-md w-full justify-center">
          <BsCheckLg className="text-xl text-darkblue h-full" />
        </section>
        <section className="sm:text-left text-center">
          <h3 className="text-2xl mb-1 text-white font-medium font-playfair">{heading}</h3>
          <p className="text-base font-normal text-white"> {text} </p>
        </section>
      </section>
      <p className="text-darkblue mt-4 text-lg xs:text-xl font-semibold text-center"> <span className="font-bold"> Login </span> to check your application status </p>
    </>
  );
};

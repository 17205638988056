import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { visaApplication } from "../../../features/unauth-features/visaSlice";
import { ButtonDark } from "../../buttons/Buttons";
import { CustomDatePicker } from "../../inputs/CustomDatePicker";
import { CustomInput } from "../../inputs/CustomInput"
import { ErrorToast } from "../../toast/toasts";
import "./TripInformation.css"


const schema  = yup.object().shape({
    fullName: yup.string().required("full name is required"),
    address: yup.string().required("address is required"),
    email:  yup.string().email().required("email is required"),
    phone: yup.string().required("phone number is required"),
    startDate: 
        yup.date()
        .min(new Date(), 'start date must be greater than today')
        .required("start date for trip is required"),
    endDate: 
        yup.date()
        .min(yup.ref('startDate'), 'end date must be after start date')
        .required("end date for trip is required"),
});

export const TripInformation  = ({ setRoute, setSteps, routes, steps, setCurrentStep, currentStep }) => {
    const dispatch = useDispatch();
    const { locationAndDestination, tripLocation } = useSelector((state) => state.general);
    const [ loading, setLoading ] = useState(false);
    
    const method = useForm({
        resolver: yupResolver(schema),
        defaultValues: {}
    });
    const { handleSubmit, reset } = method;
    
    useEffect(() => {
        const draftFromStorage = JSON.parse(localStorage.getItem('visaDraft'));
        if (draftFromStorage) {
            const savedValues = {
                fullName: draftFromStorage.fullName,
                address: draftFromStorage.address,
                email: draftFromStorage.email,
                phone: draftFromStorage.phone,
                startDate: draftFromStorage.startTripDate,
                endDate: draftFromStorage.endTripDate,
            }
            reset(savedValues);   
        }
    }, [reset]);
    
    const handleClick = (data) => {
        setLoading(true)
        const payload = {
            location: locationAndDestination.location,
            going: locationAndDestination.destination,
            from: tripLocation.from,
            startTripDate:  moment(data.startDate).format("YYYY-MM-DD"),
            endTripDate:  moment(data.endDate).format("YYYY-MM-DD"),
            destination: locationAndDestination.destination,
            visaType: tripLocation.visaType,
            fullName: data.fullName,
            email: data.email,
            phone: data.phone,
            address: data.address
        }
        dispatch(visaApplication(payload))
        .unwrap()
        .then((res) => {
            setLoading(false);
            const updatedSteps = steps.map((step) => step.number === currentStep ? {...step, completed: true} : step);
            console.log(res);
            setSteps(updatedSteps);
            setCurrentStep(3);
            setRoute(routes[2]);
        })
        .catch((err) => {
            setLoading(false);
            ErrorToast( err?.data?.error || "Error occurred");
        });
        
    }


    return (
        <section className="w-full max-w-[1000px] mx-auto xs:shadow-[0px_2px_12px_rgba(0,0,0,0.12)] sm:px-[6%] py-[8%] rounded-md mb-44">
            <section>
                <CustomInput
                method={method}
                name={"fullName"}
                label={"Full name"}
                placeholder={"John Doe"}
                type={'text'}
                />
            </section>
            <section className="information">
                <section>
                    <CustomInput
                    method={method}
                    name={"email"}
                    label={"Email"}
                    placeholder={"Type here..."}
                    type={'email'}
                    />
                </section>
                <section>
                    <CustomInput
                    method={method}
                    name={"phone"}
                    label={"Phone number"}
                    placeholder={"Type here..."}
                    type={'tel'}
                    />
                </section>
            </section>
            <section className="information">
                <section className="">
                    <CustomDatePicker 
                    name={"startDate"}
                    label={"Estimated start of your trip"}
                    method={method}
                    />
                </section>
                <section>
                    <CustomDatePicker
                    name={"endDate"}
                    label={"Estimated end of your trip"}
                    method={method}
                    />
                </section>
            </section>
            <section className="mt-4">
                <section>
                    <CustomInput
                    method={method}
                    name={"address"}
                    label={"Address"}
                    placeholder={"Type here..."}
                    type={'text'}
                    />
                </section>
            </section>
            <section className={`mt-6 flex w-fit`}>
                <ButtonDark 
                handleClick={handleSubmit(handleClick)}
                disabled={loading}
                >
                    { loading ? <p className={`${loading && "loading-dot"} pr-2`}> Loading  </p> : 'Continue'}
                </ButtonDark>
            </section>
        </section>
    )
}
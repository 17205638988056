import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { unauth_api } from "../../services/unauth-services/axiosInstance";
import { BASE_URL } from "../../utils/constants";

const initialState = {
  countries: {
    status: 'loading',
    result: [
      { name: 'Afghanistan' },
      { name: 'Albania' },
      { name: 'Algeria' },
      { name: 'Andorra' },
      { name: 'Angola' },
      { name: 'Antigua and Barbuda' },
      { name: 'Argentina' },
      { name: 'Armenia' },
      { name: 'Australia' },
      { name: 'Austria' },
      { name: 'Azerbaijan' },
      { name: 'Bahamas' },
      { name: 'Bahrain' },
      { name: 'Bangladesh' },
      { name: 'Barbados' },
      { name: 'Belarus' },
      { name: 'Belgium' },
      { name: 'Belize' },
      { name: 'Benin' },
      { name: 'Bhutan' },
      { name: 'Bolivia' },
      { name: 'Bosnia and Herzegovina' },
      { name: 'Botswana' },
      { name: 'Brazil' },
      { name: 'Brunei' },
      { name: 'Bulgaria' },
      { name: 'Burkina Faso' },
      { name: 'Burundi' },
      { name: 'Cambodia' },
      { name: 'Cameroon' },
      { name: 'Canada' },
      { name: 'Cape Verde' },
      { name: 'Central African Republic' },
      { name: 'Chad' },
      { name: 'Chile' },
      { name: 'China' },
      { name: 'Colombia' },
      { name: 'Comoros' },
      { name: 'Congo, Republic of the' },
      { name: 'Congo, Democratic Republic of the' },
      { name: 'Costa Rica' },
      { name: "Cote d'Ivoire" },
      { name: 'Croatia' },
      { name: 'Cuba' },
      { name: 'Cyprus' },
      { name: 'Czech Republic' },
      { name: 'Denmark' },
      { name: 'Djibouti' },
      { name: 'Dominica' },
      { name: 'Dominican Republic' },
      { name: 'East Timor (Timor-Leste)' },
      { name: 'Ecuador' },
      { name: 'Egypt' },
      { name: 'El Salvador' },
      { name: 'Equatorial Guinea' },
      { name: 'Eritrea' },
      { name: 'Estonia' },
      { name: 'Ethiopia' },
      { name: 'Fiji' },
      { name: 'Finland' },
      { name: 'France' },
      { name: 'Gabon' },
      { name: 'Gambia' },
      { name: 'Georgia' },
      { name: 'Germany' },
      { name: 'Ghana' },
      { name: 'Greece' },
      { name: 'Grenada' },
      { name: 'Guatemala' },
      { name: 'Guinea' },
      { name: 'Guinea-Bissau' },
      { name: 'Guyana' },
      { name: 'Haiti' },
      { name: 'Honduras' },
      { name: 'Hungary' },
      { name: 'Iceland' },
      { name: 'India' },
      { name: 'Indonesia' },
      { name: 'Iran' },
      { name: 'Iraq' },
      { name: 'Ireland' },
      { name: 'Israel' },
      { name: 'Italy' },
      { name: 'Jamaica' },
      { name: 'Japan' },
      { name: 'Jordan' },
      { name: 'Kazakhstan' },
      { name: 'Kenya' },
      { name: 'Kiribati' },
      { name: 'Korea, North' },
      { name: 'Korea, South' },
      { name: 'Kosovo' },
      { name: 'Kuwait' },
      { name: 'Kyrgyzstan' },
      { name: 'Laos' },
      { name: 'Latvia' },
      { name: 'Lebanon' },
      { name: 'Lesotho' },
      { name: 'Liberia' },
      { name: 'Libya' },
      { name: 'Liechtenstein' },
      { name: 'Lithuania' },
      { name: 'Luxembourg' },
      { name: 'Macedonia' },
      { name: 'Madagascar' },
      { name: 'Malawi' },
      { name: 'Malaysia' },
      { name: 'Maldives' },
      { name: 'Mali' },
      { name: 'Malta' },
      { name: 'Marshall Islands' },
      { name: 'Mauritania' },
      { name: 'Mauritius' },
      { name: 'Mexico' },
      { name: 'Micronesia' },
      { name: 'Moldova' },
      { name: 'Monaco' },
      { name: 'Mongolia' },
      { name: 'Montenegro' },
      { name: 'Morocco' },
      { name: 'Mozambique' },
      { name: 'Myanmar (Burma)' },
      { name: 'Namibia' },
      { name: 'Nauru' },
      { name: 'Nepal' },
      { name: 'Netherlands' },
      { name: 'New Zealand' },
      { name: 'Nicaragua' },
      { name: 'Niger' },
      { name: 'Nigeria' },
      { name: 'Norway' },
      { name: 'Oman' },
      { name: 'Pakistan' },
      { name: 'Palau' },
      { name: 'Palestine' },
      { name: 'Panama' },
      { name: 'Papua New Guinea' },
      { name: 'Paraguay' },
      { name: 'Peru' },
      { name: 'Philippines' },
      { name: 'Poland' },
      { name: 'Portugal' },
      { name: 'Qatar' },
      { name: 'Romania' },
      { name: 'Russia' },
      { name: 'Rwanda' },
      { name: 'Saint Kitts and Nevis' },
      { name: 'Saint Lucia' },
      { name: 'Saint Vincent and the Grenadines' },
      { name: 'Samoa' },
      { name: 'San Marino' },
      { name: 'Sao Tome and Principe' },
      { name: 'Saudi Arabia' },
      { name: 'Senegal' },
      { name: 'Serbia' },
      { name: 'Seychelles' },
      { name: 'Sierra Leone' },
      { name: 'Singapore' },
      { name: 'Slovakia' },
      { name: 'Slovenia' },
      { name: 'Solomon Islands' },
      { name: 'Somalia' },
      { name: 'South Africa' },
      { name: 'South Sudan' },
      { name: 'Spain' },
      { name: 'Sri Lanka' },
      { name: 'Sudan' },
      { name: 'Suriname' },
      { name: 'Swaziland' },
      { name: 'Sweden' },
      { name: 'Switzerland' },
      { name: 'Syria' },
      { name: 'Taiwan' },
      { name: 'Tajikistan' },
      { name: 'Tanzania' },
      { name: 'Thailand' },
      { name: 'Timor-Leste (East Timor)' },
      { name: 'Togo' },
      { name: 'Tonga' },
      { name: 'Trinidad and Tobago' },
      { name: 'Tunisia' },
      { name: 'Turkey' },
      { name: 'Turkmenistan' },
      { name: 'Tuvalu' },
      { name: 'Uganda' },
      { name: 'Ukraine' },
      { name: 'United Arab Emirates' },
      { name: 'United Kingdom' },
      { name: 'United States of America' },
      { name: 'Uruguay' },
      { name: 'Uzbekistan' },
      { name: 'Vanuatu' },
      { name: 'Vatican City (Holy See)' },
      { name: 'Venezuela' },
      { name: 'Vietnam' },
      { name: 'Yemen' },
      { name: 'Zambia' },
      { name: 'Zimbabwe' },]
  },
  countriesWithAlias: {
    status: 'loading',
    result: [
      { name: 'Afghanistan (AF)' },
      { name: 'Albania (AL)' },
      { name: 'Algeria (DZ)' },
      { name: 'Andorra (AD)' },
      { name: 'Angola (AO)' },
      { name: 'Antigua and Barbuda (AG)' },
      { name: 'Argentina (AR)' },
      { name: 'Armenia (AM)' },
      { name: 'Australia (AU)' },
      { name: 'Austria (AT)' },
      { name: 'Azerbaijan (AZ)' },
      { name: 'Bahamas (BS)' },
      { name: 'Bahrain (BH)' },
      { name: 'Bangladesh (BD)' },
      { name: 'Barbados (BB)' },
      { name: 'Belarus (BY)' },
      { name: 'Belgium (BE)' },
      { name: 'Belize (BZ)' },
      { name: 'Benin (BJ)' },
      { name: 'Bhutan (BT)' },
      { name: 'Bolivia (BO)' },
      { name: 'Bosnia and Herzegovina (BA)' },
      { name: 'Botswana (BW)' },
      { name: 'Brazil (BR)' },
      { name: 'Brunei (BN)' },
      { name: 'Bulgaria (BG)' },
      { name: 'Burkina Faso (BF)' },
      { name: 'Burundi (BI)' },
      { name: 'Cambodia (KH)' },
      { name: 'Cameroon (CM)' },
      { name: 'Canada (CA)' },
      { name: 'Cape Verde (CV)' },
      { name: 'Central African Republic (CF)' },
      { name: 'Chad (TD)' },
      { name: 'Chile (CL)' },
      { name: 'China (CN)' },
      { name: 'Colombia (CO)' },
      { name: 'Comoros (KM)' },
      { name: 'Costa Rica (CR)' },
      { name: 'Croatia (HR)' },
      { name: 'Cuba (CU)' },
      { name: 'Cyprus (CY)' },
      { name: 'Czech Republic (CZ)' },
      { name: 'Democratic Republic of the Congo (CD)' },
      { name: 'Denmark (DK)' },
      { name: 'Djibouti (DJ)' },
      { name: 'Dominica (DM)' },
      { name: 'Dominican Republic (DO)' },
      { name: 'East Timor (TL)' },
      { name: 'Ecuador (EC)' },
      { name: 'Egypt (EG)' },
      { name: 'El Salvador (SV)' },
      { name: 'Equatorial Guinea (GQ)' },
      { name: 'Eritrea (ER)' },
      { name: 'Estonia (EE)' },
      { name: 'Ethiopia (ET)' },
      { name: 'Fiji (FJ)' },
      { name: 'Finland (FI)' },
      { name: 'France (FR)' },
      { name: 'Gabon (GA)' },
      { name: 'Gambia (GM)' },
      { name: 'Georgia (GE)' },
      { name: 'Germany (DE)' },
      { name: 'Ghana (GH)' },
      { name: 'Greece (GR)' },
      { name: 'Grenada (GD)' },
      { name: 'Guatemala (GT)' },
      { name: 'Guinea (GN)' },
      { name: 'Guinea-Bissau (GW)' },
      { name: 'Guyana (GY)' },
      { name: 'Haiti (HT)' },
      { name: 'Honduras (HN)' },
      { name: 'Hungary (HU)' },
      { name: 'Iceland (IS)' },
      { name: 'India (IN)' },
      { name: 'Indonesia (ID)' },
      { name: 'Iran (IR)' },
      { name: 'Iraq (IQ)' },
      { name: 'Ireland (IE)' },
      { name: 'Israel (IL)' },
      { name: 'Italy (IT)' },
      { name: 'Ivory Coast (CI)' },
      { name: 'Jamaica (JM)' },
      { name: 'Japan (JP)' },
      { name: 'Jordan (JO)' },
      { name: 'Kazakhstan (KZ)' },
      { name: 'Kenya (KE)' },
      { name: 'Kiribati (KI)' },
      { name: 'Kosovo (XK)' },
      { name: 'Kuwait (KW)' },
      { name: 'Kyrgyzstan (KG)' },
      { name: 'Laos (LA)' },
      { name: 'Latvia (LV)' },
      { name: 'Lebanon (LB)' },
      { name: 'Lesotho (LS)' },
      { name: 'Liberia (LR)' },
      { name: 'Libya (LY)' },
      { name: 'Liechtenstein (LI)' },
      { name: 'Lithuania (LT)' },
      { name: 'Luxembourg (LU)' },
      { name: 'Madagascar (MG)' },
      { name: 'Malawi (MW)' },
      { name: 'Malaysia (MY)' },
      { name: 'Maldives (MV)' },
      { name: 'Mali (ML)' },
      { name: 'Malta (MT)' },
      { name: 'Marshall Islands (MH)' },
      { name: 'Mauritania (MR)' },
      { name: 'Mauritius (MU)' },
      { name: 'Mexico (MX)' },
      { name: 'Micronesia (FM)' },
      { name: 'Moldova (MD)' },
      { name: 'Monaco (MC)' },
      { name: 'Mongolia (MN)' },
      { name: 'Montenegro (ME)' },
      { name: 'Morocco (MA)' },
      { name: 'Mozambique (MZ)' },
      { name: 'Myanmar (MM)' },
      { name: 'Namibia (NA)' },
      { name: 'Namibia (NA)' },
      { name: 'Nauru (NR)' },
      { name: 'Nepal (NP)' },
      { name: 'Netherlands (NL)' },
      { name: 'New Zealand (NZ)' },
      { name: 'Nicaragua (NI)' },
      { name: 'Niger (NE)' },
      { name: 'Nigeria (NG)' },
      { name: 'North Korea (KP)' },
      { name: 'North Macedonia (MK)' },
      { name: 'Norway (NO)' },
      { name: 'Oman (OM)' },
      { name: 'Pakistan (PK)' },
      { name: 'Palau (PW)' },
      { name: 'Palestine (PS)' },
      { name: 'Panama (PA)' },
      { name: 'Papua New Guinea (PG)' },
      { name: 'Paraguay (PY)' },
      { name: 'Peru (PE)' },
      { name: 'Philippines (PH)' },
      { name: 'Poland (PL)' },
      { name: 'Portugal (PT)' },
      { name: 'Qatar (QA)' },
      { name: 'Romania (RO)' },
      { name: 'Russia (RU)' },
      { name: 'Rwanda (RW)' },
      { name: 'Saint Kitts and Nevis (KN)' },
      { name: 'Saint Lucia (LC)' },
      { name: 'Saint Vincent and the Grenadines (VC)' },
      { name: 'Samoa (WS)' },
      { name: 'San Marino (SM)' },
      { name: 'Sao Tome and Principe (ST)' },
      { name: 'Saudi Arabia (SA)' },
      { name: 'Senegal (SN)' },
      { name: 'Serbia (RS)' },
      { name: 'Seychelles (SC)' },
      { name: 'Sierra Leone (SL)' },
      { name: 'Singapore (SG)' },
      { name: 'Slovakia (SK)' },
      { name: 'Slovenia (SI)' },
      { name: 'Solomon Islands (SB)' },
      { name: 'Somalia (SO)' },
      { name: 'South Africa (ZA)' },
      { name: 'South Korea (KR)' },
      { name: 'South Sudan (SS)' },
      { name: 'Spain (ES)' },
      { name: 'Sri Lanka (LK)' },
      { name: 'Sudan (SD)' },
      { name: 'Suriname (SR)' },
      { name: 'Swaziland (SZ)' },
      { name: 'Sweden (SE)' },
      { name: 'Switzerland (CH)' },
      { name: 'Syria (SY)' },
      { name: 'Taiwan (TW)' },
      { name: 'Tajikistan (TJ)' },
      { name: 'Tanzania (TZ)' },
      { name: 'Thailand (TH)' },
      { name: 'Timor-Leste (TL)' },
      { name: 'Togo (TG)' },
      { name: 'Tonga (TO)' },
      { name: 'Trinidad and Tobago (TT)' },
      { name: 'Tunisia (TN)' },
      { name: 'Turkey (TR)' },
      { name: 'Turkmenistan (TM)' },
      { name: 'Tuvalu (TV)' },
      { name: 'Uganda (UG)' },
      { name: 'Ukraine (UA)' },
      { name: 'United Arab Emirates (AE)' },
      { name: 'United Kingdom (GB)' },
      { name: 'United States of America (US)' },
      { name: 'Uruguay (UY)' },
      { name: 'Uzbekistan (UZ)' },
      { name: 'Vanuatu (VU)' },
      { name: 'Vatican City (VA)' },
      { name: 'Venezuela (VE)' },
      { name: 'Vietnam (VN)' },
      { name: 'Yemen (YE)' },
      { name: 'Zambia (ZM)' },
      { name: 'Zimbabwe (ZW)' },
    ]
  },
  visaTypes: {
    status: 'loading',
    result: [
      { name: 'Student Visa' },
      { name: 'Medical Tourism Visa' },
      { name: 'Immigration Visa' },
      { name: 'Vacation Visa' },
      { name: 'Work Visa' },
    ]
  },
  passportTypes: {
    status: 'loading',
    result: [
      { name: 'Adult Passport' },
      { name: 'Children Passport' },
    ]
  },
};



export const getHome = createAsyncThunk(
  'Home/option',
  async (_, thunkAPI) => {
    try {
      const { data } = await unauth_api.get(`${BASE_URL}`);

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);


export const getAllCountries = createAsyncThunk(
  'getAllCountries/option',
  async (_, thunkAPI) => {
    try {
      const { data } = await unauth_api.get(`${BASE_URL}/countries`);

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getAllCountriesWithAlias = createAsyncThunk(
  'getAllCountriesWithAlias/option',
  async (_, thunkAPI) => {
    try {
      const { data } = await unauth_api.get(`${BASE_URL}/countries-with-alais`);

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);


export const getVisaTypes = createAsyncThunk(
  'getVisaTypes/option',
  async (_, thunkAPI) => {
    try {
      const { data } = await unauth_api.get(`${BASE_URL}/visas`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const getPassportTypes = createAsyncThunk(
  'getPassportTypes/option',
  async (_, thunkAPI) => {
    try {
      const { data } = await unauth_api.get(`${BASE_URL}/passports`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const optionSlice = createSlice({
  name: 'option',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
          // get all countries
      .addCase(getAllCountries.pending, (state, action) => {
        state.countries.status = 'loading';
      })
      .addCase(getAllCountries.fulfilled, (state, action) => {
        state.countries.status = 'idle';
        state.countries.result = action.payload.data;
      })
      .addCase(getAllCountries.rejected, (state, action) => {
        state.countries.status = 'idle';
      })
          // get all countries with alias
      .addCase(getAllCountriesWithAlias.pending, (state, action) => {
        state.countriesWithAlias.status = 'loading';
      })
      .addCase(getAllCountriesWithAlias.fulfilled, (state, action) => {
        state.countriesWithAlias.status = 'idle';
        state.countriesWithAlias.result = action.payload.data;
      })
      .addCase(getAllCountriesWithAlias.rejected, (state, action) => {
        state.countriesWithAlias.status = 'idle';
      })
        // get all visa types
      .addCase(getVisaTypes.pending, (state, action) => {
        state.visaTypes.status = 'loading';
      })
      .addCase(getVisaTypes.fulfilled, (state, action) => {
        state.visaTypes.status = 'idle';
        state.visaTypes.result = action.payload.data;
      })
      .addCase(getVisaTypes.rejected, (state, action) => {
        state.visaTypes.status = 'idle';
      })
        // get all passport types
      .addCase(getPassportTypes.pending, (state, action) => {
        state.passportTypes.status = 'loading';
      })
      .addCase(getPassportTypes.fulfilled, (state, action) => {
        state.passportTypes.status = 'idle';
        state.passportTypes.result = action.payload.data;
      })
      .addCase(getPassportTypes.rejected, (state, action) => {
        state.passportTypes.status = 'idle';
      })
  }
});

export default optionSlice.reducer;

import React from "react";
import { ConfirmationBox } from "./ConfirmationBox";
import { NeedHelp } from "./NeedHelp";

function Confirmation() {
  const contactOptions = [
    {
      heading: "Hotline",
      content: "08139153149"
    },
    {
      heading: "Email",
      content: "info@ogavisa.co"
    }
  ];
  return (
    <>
      <section className="flex flex-col md:flex-row gap-y-8 md:gap-x-8 mt-[60px] mb-[300px] px-[6%]">
        <section className="pass-confirm basis-3/3 md:basis-2/3">
          <ConfirmationBox
            heading={"Thank You. Your Application Is Confirmed Now."}
            text={
              "A confirmation email has been sent to your provided email address."
            }
          />
        </section>
        <section className="basis-3/3 md:basis-1/3">
          <NeedHelp heading={"Need Help ?"} contactOptions={contactOptions} />
        </section>
      </section>
    </>
  );
}

export default Confirmation;

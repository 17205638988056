import React, { useEffect, useState } from "react";
import { ButtonDark } from "../../buttons/Buttons";
import { CustomInput } from "../../inputs/CustomInput";
import { CustomSelect } from "../../inputs/CustomSelect";
import { SubHeading } from "./SubHeading";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { passportApplication } from "../../../features/unauth-features/passportSlice";
import { ErrorToast } from "../../toast/toasts";

const schema = yup.object().shape({
  fullName: yup.string().required("full name is required"),
  email: yup.string().email().required("email is required"),
  phoneNumber: yup.string().required("phone number is required"),
  passportType: yup.string().required("passport type is required"),
  address: yup.string().required("address is required"),
  numberOfApplicants: yup.number().typeError("number of applicants must be a number").required("Please input the number of applicant"),
});

function UserDetailsForm({ setRoute, routes }) {

  const dispatch = useDispatch();
  const { passportTypes } = useSelector((state) => state.option);
  const [loading, setLoading] = useState(false);

  const method = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
    }
  });
  const { handleSubmit, reset } = method;

      
  useEffect(() => {
    const draftFromStorage = JSON.parse(localStorage.getItem('passportDraft'));
    if (draftFromStorage) {
        const savedValues = {
            fullName: draftFromStorage.fullName,
            email: draftFromStorage.email,
            phoneNumber: draftFromStorage.phoneNumber,
            passportType: draftFromStorage.passportType,
            address: draftFromStorage.address,
            numberOfApplicants: draftFromStorage.numberOfApplicants,
        }
        reset(savedValues);   
    }
}, [reset])

  const handleClick = (data) => {
    setLoading(true)
    const payload = {
      fullName: data.fullName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      passportType: data.passportType,
      address: data.address,
      numberOfApplicants: data.numberOfApplicants,
    }
    dispatch(passportApplication(payload))
      .unwrap()
      .then((res) => {
        setLoading(false);
        setRoute(routes[1]);
      })
      .catch((err) => {
        setLoading(false);
        ErrorToast(err?.data?.error || err?.data?.message || err?.data || "Error occurred");
      });

  }

  const modPassportTypes = passportTypes?.result.map((passport) => ({
    value: passport.name,
    label: passport.name,
  }))

  return (
    <>
      <section>
        <SubHeading text={"User Details"} />
        <form className="bg-white shadow-round rounded-md mt-[30px] mb-[80px] sm:p-[50px] p-[30px] w-full mx-auto sm:max-w-screen-lg">
          <CustomInput
            method={method}
            name={"fullName"}
            label={"Full name"}
            placeholder={"John Doe"}
          />
          <section className="flex flex-wrap -mx-3">
            <section className="w-full md:w-1/2 px-3">
              <CustomInput
                method={method}
                name={"email"}
                label={"Email"}
                placeholder={"Type here..."}
              />
            </section>
            <section className="w-full md:w-1/2 px-3">
              <CustomInput
                method={method}
                name={"phoneNumber"}
                label={"Phone number"}
                placeholder={"Type here..."}
              />
            </section>
          </section>
          <section className="mb-5">
            <CustomSelect
              method={method}
              name={"passportType"}
              label={"Who are you applying for?"}
              placeholder={"Adult"}
              options={modPassportTypes}
            />
          </section>
          <section className="mb-2">
            <CustomInput
              method={method}
              name={"address"}
              label={"Pick up address"}
              placeholder={"Type here"}
            />
          </section>
          <section className="mb-2">
            <CustomInput
              method={method}
              name={"numberOfApplicants"}
              label={"Number of applicant"}
              placeholder={"Type here"}
              type={"number"}
            />
          </section>
          <section className="mt-6 flex w-fit">
            <ButtonDark
            disabled={loading}
            handleClick={handleSubmit(handleClick)}
            >
              { loading ? <p className={`${loading && "loading-dot"} pr-2`}> Loading  </p> : 'Continue'}
            </ButtonDark>
          </section>
        </form>

      </section>
    </>
  );
}

export default UserDetailsForm;

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { labelStyling } from "./InputStyles";
import './inputs.css';
import { Controller } from "react-hook-form";
import moment from "moment/moment";
import { ErrorMsg } from "../../alert/ErrorMsg";

export const CustomDatePicker = ({ name, label, method }) => {
  const {
    control,
    formState: { errors },
  } = method;

  return (
    <>
      <section className="w-full">
        <label style={labelStyling}>{ label }</label>
        <Controller
        control={control}
        name={`${name}`}
        render={({ field: { onChange, value } }) => (
          <ReactDatePicker
            placeholderText="Set date"
            onChange={(date) => onChange(date)}
            selected={value ? moment(value).toDate() : ""}
            className="datepicker-input focus:outline-none"
            peekNextMonth
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
          />
        )}
      />
      {errors[name] && <ErrorMsg msg={errors[name]?.message} />}
      </section>
    </>
  );
};

import React from 'react';
import { numberWithCommas } from '../../utils/numbers';

const Amount = ({ amount }) => {
  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '5px' }}>₦</span>{' '}
      {numberWithCommas(Number(amount).toFixed(2))}
    </span>
  );
};

export default Amount;
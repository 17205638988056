import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


export const Others = () => {

    return (
        <>
            <section className="text-black mt-10 md:mt-0">
                <section>
                    <h4 className={`text-xl font-medium`} > Quick Links </h4>
                    <div className="border border-pending mt-2 w-[66px]"></div>
                </section>
                <section className="mt-10 md:mt-[56px] text-base">
                    <ul className="space-y-4"> 
                        <Link to={`/login`} > 
                            <li className="max-w-[160px] transition hover:text-pending hover:scale-100 delay-150 ease-in"> Account  </li>
                        </Link>
                        <HashLink to={`/privacy#privacy-policy`}> 
                            <li className="max-w-[160px] transition hover:text-pending hover:scale-100 delay-150 ease-in"> Privacy Policy  </li>
                        </HashLink>
                        <HashLink to={`/terms#terms`}> 
                            <li className="max-w-[160px] transition hover:text-pending hover:scale-100 delay-150 ease-in"> Terms and Conditions  </li>
                        </HashLink>
                        <HashLink to={`/application-steps#steps`}> 
                            <li className="max-w-[160px] transition hover:text-pending hover:scale-100 delay-150 ease-in"> Application Steps  </li>
                        </HashLink>
                    </ul>
                </section>
            </section>

            <section className="text-black mt-10 md:mt-0">
                <section>
                    <h4 className={`text-xl font-medium`} > Explore </h4>
                    <div className="border border-pending mt-2 w-[66px]"></div>
                </section>
                <section className="mt-10 md:mt-[56px] text-base">
                    <ul className="space-y-4">
                        <HashLink to={`/landing#destinations`}> 
                            <li className="max-w-[160px] transition hover:text-pending hover:scale-100 delay-150 ease-in"> Favorite Destination  </li>
                        </HashLink>
                        <HashLink to={`/visa-application#visa-application`}> 
                            <li className="max-w-[160px] transition hover:text-pending hover:scale-100 delay-150 ease-in"> Visa Application  </li>
                        </HashLink>
                        <HashLink to={`/passport-application#passport-application`}> 
                            <li className="max-w-[160px] transition hover:text-pending hover:scale-100 delay-150 ease-in"> Passport Application  </li>
                        </HashLink>
                    </ul>
                </section>
            </section>

            <section className="text-black mt-10 md:mt-0">
                <section>
                    <h4 className={`text-xl font-medium`} >Contact </h4>
                    <div className="border border-pending mt-2 w-[66px]"></div>
                </section>
                <section className="mt-10 md:mt-[56px] text-base">
                    <ul className="space-y-4"> 
                            <li className="max-w-[160px] transition hover:text-pending hover:scale-100 delay-150 ease-in"> 
                                12 wemi akinsola close, Allen Avenue,Ikeja, Lagos  
                            </li>
                            <li className="max-w-[160px] transition hover:text-pending hover:scale-100 delay-150 ease-in"> 08139153149  </li>
                            <li className="max-w-[160px] transition hover:text-pending hover:scale-100 delay-150 ease-in"> info@ogavisa.co  </li>
                    </ul>
                </section>
            </section>
        </>
    )
}
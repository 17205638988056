import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addToLocationAndDestination } from "../../../../features/unauth-features/generalSlice";
import { CustomSelect } from "../../../inputs/CustomSelect"
import { ButtonDark } from "../../../buttons/Buttons";
import { GrClose } from "react-icons/gr";
import "./Modal.css";



export const Modal = ({ setModal }) => {

    const schema  = yup.object().shape({
        location: yup.string().required("current location is required"),
        destination: yup.string().required("destination is required"),
    });

    const draftFromStorage = JSON.parse(localStorage.getItem('visaDraft'));
    const {  visaDraft } = useSelector((state) => state.visa);
    const { country } = useSelector((state) => state.general);
    const { countries } = useSelector((state) => state.option);
    const dispatch = useDispatch();
    const method = useForm({
        resolver: yupResolver(schema)
    })
    const { handleSubmit, setValue } = method;

    useEffect(() => {
        setValue("location",country ? country : draftFromStorage ? draftFromStorage.location : ""); 
        setValue("destination", draftFromStorage ? draftFromStorage.destination : "" );
    }, [visaDraft, setValue, draftFromStorage, country])

    const handleClick = (data) => {
        const payload = {
            location: data.location,
            destination: data.destination
        }
        dispatch(addToLocationAndDestination(payload));
        setModal(false);
    }

    const modCountries = countries?.result.map((country) => ({
        value: country.name,
        label: country.name,
    }));

    return (
        <section id="authentication-modal"  className="fixed shadow-md rounded-lg top-1/2 left-1/2 z-50 bg-white p-3 sm:mx-0 w-full max-w-[400px] h-[400px] -translate-x-2/4 -translate-y-2/4">


            {/* // <!-- Main modal --> */}
            <div  tabIndex="-1" aria-hidden="true">
                <section className="flex items-center justify-between">
                    <h1 className="text-xl sm:text-2xl my-3 text-darkblue font-semibold text-center"> Enter Info to Continue </h1>
                    <GrClose className="text-darkblue text-base cursor-pointer" onClick={() => setModal(false)} />
                </section>
                <div className="relative w-full h-36 max-w-md md:h-auto">
                    {/* <!-- Modal content --> */}
                    <section className="welcome mt-6">
                        <section className="">
                            <CustomSelect
                            method={method} 
                            name={'location'} 
                            label={'Current Location'}
                            placeholder={'Nigeria'}
                            options={modCountries}
                            />
                        </section>

                        <section className="">
                            <CustomSelect
                            method={method}
                            name={'destination'} 
                            label={'Where is your destination?'} 
                            placeholder={'Your Destination'}
                            options={modCountries} 
                            />
                        </section>
                        

                        <section className="h-[50px] sm:h-[50px] w-fit flex items-center border -mt-3" onClick={handleSubmit(handleClick)}>
                            <ButtonDark>
                                Continue
                            </ButtonDark>
                        </section>
                    </section>
                </div>
            </div> 
        </section>
    )
}

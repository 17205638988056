import React from 'react'

const Terms = () => {
  return (
    <section className='px-[6%] my-5' id='terms'>
      <h1 className="text-darkblue font-bold text-3xl text-center lg:text-left md:text-5xl font-playfair"> Terms And Conditions </h1>
      <ol className='text-base sm:text-lg text-color4 text-center lg:text-left font-normal mt-5 '>
        <li className='mt-5'>
          <h2 className='text-darkblue font-medium'>
            Accuracy of information:
          </h2>
           <p>
            You are responsible for providing accurate and truthful information in your visa application. Providing false information can result in your application being denied and may also have legal consequences.
           </p>
        </li>
        <li className='mt-5'>
          <h2 className='text-darkblue font-medium'>
            Processing times:
          </h2>
           <p>
            Visa processing times can vary depending on the country you're traveling to and other factors. While OgaVisa will make every effort to process your application quickly, We cannot guarantee a specific processing time.
           </p>
        </li>
        <li className='mt-5'>
          <h2 className='text-darkblue font-medium'>
            Fees:
          </h2>
           <p>
            Ogavisa will charge a fee for processing your visa application. The fee may vary depending on the type of visa and how quickly you need it. Make sure you understand the fee structure and payment options before submitting your application.
           </p>
        </li>
        <li className='mt-5'>
          <h2 className='text-darkblue font-medium'>
            Refunds:
          </h2>
           <p>
            In the event that your visa application is denied, OgaVisa may offer a refund of their processing fee. However, they may not be able to refund any fees paid to government agencies or other third-party service providers.
           </p>
        </li>
        <li className='mt-5'>
          <h2 className='text-darkblue font-medium'>
            Privacy:
          </h2>
           <p>
            OgaVisa should protect your personal information and keep it confidential. They may need to share your information with government agencies or other third-party service providers to process your visa application, but they should only do so with your consent or as required by law.
           </p>
        </li>
      </ol>
      <p className="text-base sm:text-lg text-color3 text-center lg:text-left font-normal mt-5">
        It's important to read and understand the terms and conditions of our visa processing company before using the services. If you have any questions or concerns, don't hesitate to contact Ogavisa’s customer support team for assistance.
      </p>

    </section>
  );
}

export default Terms
import { Content } from "./Content";
import { InfoHeader } from "./InfoHeader"
import { CgProfile } from "react-icons/cg"
import { useDispatch, useSelector } from "react-redux";
import { OnlyDate } from "../../excerpts/OnlyDate";
import { getOnePerson } from "../../../features/unauth-features/visaSlice";


const content = [
    { text1: "What is your nationality?", text2: "Nigerian (NG)" },
    { text1: "Gender:", text2: "Male" },
    { text1: "Surname:", text2: "John" },
    { text1: "First name:", text2: "Doe" },
    { text1: "Middle name", text2: "Smith" },
    { text1: "Date of Birth", text2: "Feb 27, 2002" },
    { text1: "Your country of birth:", text2: "Nigeria (NG)" },
    { text1: "Your country of residence:", text2: "Nigerian (NG)" },
    { text1: "Passport number:", text2: "BEF272002" },
    { text1: "When does your passport expires?", text2: "Feb 27, 2028" },
    { text1: "Visa request location:", text2: "Nigeria" },
    { text1: "Do you live in same country where you are applying for the visa?", text2: "YES" },
];
export const GeneralInformation = ({ setRoute, setCurrentStep }) => {
    const dispatch = useDispatch();
    const { visaUpdate } = useSelector((state) => state.visa);
    return (
        <section className="w-[700px] md:w-full 2xl:w-[1000px]">
            { visaUpdate?.result?.persons.map((person, id) => (
                <section key={id}>
                    <InfoHeader icon={<CgProfile className="text-lg mr-2" />} header={"General Information"} 
                    editClick={() => {
                        dispatch(getOnePerson(person._id))
                        setRoute("general-information")
                        setCurrentStep(3)
                    }}
                    />
                    <section className="grid grid-cols-3 gap-4 mb-6">
                        <Content text1={content[0].text1} text2={person.nationality} />
                        <Content text1={content[1].text1} text2={person.gender === "M" ? "Male" : "Female"} />
                        <Content text1={content[2].text1} text2={person.surname} />
                        <Content text1={content[3].text1} text2={person.firstAndMiddleName} />
                        <Content text1={content[4].text1} text2={person.firstAndMiddleName} />
                        <Content text1={content[5].text1} text2={ <OnlyDate date={person.dob} /> } />
                        <Content text1={content[6].text1} text2={person.countryOfBirth} />
                        <Content text1={content[7].text1} text2={person.visaRequestLocation} />
                        <Content text1={content[8].text1} text2={person.passportNumber} />
                        <Content text1={content[9].text1} text2={ <OnlyDate date={person.passportExpires} /> } />
                        <Content text1={content[10].text1} text2={person.visaRequestLocation} />
                        <Content text1={content[11].text1} text2={person.liveApplicationVisa === true ? "Yes" : "No"} />
                    </section>
                </section>    
            ))}
        </section>
    )
}
import "./Confirmation.css";

export const NeedHelp = ({ heading, contactOptions }) => {
  return (
    <section className="box">
      <h3 className="text-2xl mb-3 text-darkblue font-medium font-playfair">{heading}</h3>
      <ul className="flex flex-col gap-y-2">
        {contactOptions.map((item, id) => (
          <li key={id}>
            {item.heading}: {item.content}
          </li>
        ))}
      </ul>
    </section>
  );
};

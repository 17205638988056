import React from 'react'
import { useNavigate } from 'react-router-dom';
import { ButtonDark } from "../../components/buttons/Buttons";



const China = () => {
    const navigate = useNavigate();
  return (
    <section className='max-w-3xl mx-auto my-4'>
      <h1 className=" text-darkblue font-bold text-4xl text-center lg:text-left md:text-6xl font-playfair"> China </h1>
      <section className='text-base sm:text-lg text-color4 text-center lg:text-left font-normal mt-3 '>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Visa Requirements:
          </p>
          <p>
          If you are not a citizen of a country that is visa-exempt for China, you will need a visa to enter China for tourism, business, or other short-term visits. There are several types of visas available, including tourist visas, business visas, and transit visas.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Visa Application Process:
          </p>
          <p>
            To apply for a visa, you will need to provide a valid passport with at least six months of validity remaining and at least one blank visa page. You will also need to provide a completed visa application form, a recent passport-sized photograph, and any other supporting documents required by the Chinese embassy or consulate.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Passport Requirements:
          </p>
          <p>
          To enter China, your passport must be valid for at least six months beyond the date of your planned departure from China. You should also ensure that you have at least one blank visa page in your passport for the visa stamp.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
              Passport Application Process:
          </p>
          <p>
          To apply for a new passport or renew an existing passport, you will need to provide a completed application form, your current passport, two recent passport-sized photographs, and any other supporting documents required by your passport issuing authority.
          </p>
        </p>

        <section className='flex justify-center mt-8'>
          <ButtonDark handleClick={() => navigate("/visa-application")}>
                    Apply For Visa Now
                </ButtonDark>
        </section>


      </section>




    </section>
  )
}

export default China
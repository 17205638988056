import { Heading2 } from "../whychooseogavisa/Heading2";
import { SliderItem } from "./SliderItem";

// import required modules
import { Autoplay, Navigation } from "swiper";

// Import Swiper React components
import { Swiper,SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./Reviews.css";
import { CircleWithImage } from "./CircleWithImage";
import reviewbg from "../../../assets/images/reviewBg.webp";



const contents = [
    { 
        name: "Oguru richard", 
        review: `The customer service team at Ogavisa are very responsive and swift. They were there for me every step of the way throughout my documentation and 
        application process.` 
    },
    { 
        name: "Okafor Chisom", 
        review: `Ogavisa platform has changed the way I see and use visa application. 
        I used the platform when I was applying for a vacation visa for my family last year, it was seamless.` 
    },
    { 
        name: "Shoyinka Adeola", 
        review: `This is simply the most simplified way to apply for any visa from the comfort of my home. Thank you OgaVisa.`
    },
    { 
        name: "Owoseni Taiwo", 
        review: `I simply tried a visitors visa on the Ogavisa platform and today my home address is Maryland,USA.` 
    },
    { 
        name: "Okon Roseline", 
        review: `Me and my family found out about the  Ogavisa platform from a friend who had used them before and we had to try it, 
        they made the application process very easy and simple. We got the vacation visa to visit Orlando, Florida` 
    },
    { 
        name: "Nwachukwu Eugene Christian", 
        review: `I have never come across any visa application company that makes the visa application process easy like Ogavisa people do. 
        They continue to raise the standard of customer care. I got my UK visa thanks to them.` 
    },
    { 
        name: "Mrs. Ronke Apata ", 
        review: `Ogavisa, Please keep up the good job. If only I have known or heard of this platform many years ago, I won't be hassling and stressing myself about 
        with crocked travel agencies. I encourage everyone to use the simplified visa application process of Ogavisa and visit a country and learn new culture.` 
    },
]

const Reviews = () => {
    return (
        <section className="px-[6%] mt-20 pb-36"
        style={{
            background: `url(${reviewbg}) no-repeat`,
            backgroundSize: "cover",
            backgroundPosition: "center"
        }}   
        >
            <Heading2 text={"Reviews By Clients"} />
            <section className="flex flex-wrap mt-10 lg:mt-16">
                <section className="mt-10 sm:mt-0 w-full mx-auto lg:max-w-[600px] lg:my-auto">
                    <Swiper 
                    navigation={true}
                    loop={true}
                    autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                    }}
                    modules={[ Autoplay,Navigation]} 
                    className="mySwiper"
                    >
                        { contents.map((content) => (
                            <SwiperSlide><SliderItem name={content.name} review={content.review} /></SwiperSlide>
                        ))
                        }
                    </Swiper>
                </section>
                <section className="w-full xl:w-fit h-[500px] mx-auto hidden sm:flex">
                    <CircleWithImage />
                </section>
            </section>
        </section>
    );
}
 
export default Reviews;
import { TypeAnimation } from "react-type-animation"

export const Text = () => {
    return (
        <section className=" mt-10 mb-5">
            <h1 className="text-3xl text-center lg:text-left md:text-5xl lg:w-[700px] text-darkblue mb-3.5 capitalize font-semibold">
                <TypeAnimation
                    sequence={[
                        // Same String at the start will only be typed once, initially
                        'Simplify Your Visa Application Process',
                        1000,
                        'Simplify Your Passport Application Process',
                    ]}
                    speed={200}
                    repeat={Infinity}
                />
            </h1>
            <p className="font-normal text-center lg:text-left text-xl lg:w-[550px] text-color4">
                Applying for a visa can be a complex and time-consuming process. Say goodbye to confusing government websites and 
                let us handle your visa application process.
            </p>
        </section>
    )
}
export const Step = ({ name, number, color, borderColor, bgColor, show }) => {
    return (
        <section className={`flex items-center`}
         style={{color: `${color}`}}
        >
            <section className="h-[30px] w-[30px] flex items-center justify-center rounded-full transition-all ease-in"
            style={{ 
                border: `1px solid ${borderColor}`,
                background: `${bgColor}`
            }}
            >
                <p className="font-medium text-base text-center"> {number} </p>
            </section>
            <p className="font-normal text-sm ml-2.5"> {name} </p>
            {show &&
                <div className="hidden md:block w-[26px] ml-2.5 mt-1.5"
                style={{border: `1px solid ${color}`}}
                ></div>
            }

        </section>
    )
}
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import profile from "../../../assets/images/profile.svg";
import dropdownIcon from "../../../assets/icons/dropdown.svg";
import { clearToken, userLogout } from "../../../features/unauth-features/loginSlice";
import { setActiveTab } from "../../../features/auth-features/userSlice";
import Skeleton from "react-loading-skeleton";

export const UserWithIcon = () => {
    const { userInfo } = useSelector((state) => state.user);
    const [ dropdown, setDropdown] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        /**
         * removeTooltip if clicked on outside of parent element
         */
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setDropdown(false);
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref]);

    return (
      <>
      { userInfo.status === 'loading' ?
        <Skeleton width={120} height={35} />
        :
        <section className="relative" ref={ref}>
            <section className="flex items-center space-x-2.5 w-fit cursor-pointer" >
                <Link to={"/"} className="truncate w-20 xs:w-fit"> <span className="text-base sm:text-lg text-pending font-medium"> { userInfo.result.fullName } </span> </Link>
                <div className="w-fit flex items-center space-x-2" onClick={() => setDropdown(dropdown => !dropdown)} >
                  <img src={profile} alt={"profile-icon"} height={40} width={40} />
                  <img src={dropdownIcon} alt={"arrow-down-icon"} />
                </div>
            </section>
            { dropdown &&  <UserWithIconDropdown />}
        </section>
      }
      </>
    )
};

export const UserWithIconDropdown = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLogOut = () => {
        dispatch(userLogout());
        dispatch(clearToken());
    };

    return (
        <div className="absolute right-0 z-10 text-black font-medium mt-5 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
        <div className="py-2.5 space-y-1 text-[15px]" role="none">
          {/* <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" --> */}
          <p 
          className="block px-4 py-2 hover:bg-gray-100 cursor-pointer" 
          role="menuitem" 
          tabIndex="-1" 
          id="menu-item-0"
          onClick={() => {
            navigate("/?tab=profile");
            dispatch(setActiveTab("profile"))
          }}
          > 
            Profile
          </p>
          <p 
          className="block px-4 py-2 hover:bg-gray-100 cursor-pointer" 
          role="menuitem" 
          tabIndex="-1" 
          id="menu-item-1"
          onClick={() => {
            navigate("/?tab=application-status");
            dispatch(setActiveTab("application-status"))
          }}
          >
            Application Status
          </p>
          <p 
          className="block w-full px-4 py-2 text-left hover:bg-gray-100 cursor-pointer" 
          role="menuitem" tabIndex="-1" id="menu-item-3"
          onClick={handleLogOut}
          >
            Log out
          </p>
        </div>
      </div>
    )
}
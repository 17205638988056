import { useEffect, useState } from "react";
import { TabHeading } from "../../components/tabHeading/TabHeading";
import { Previous } from "../../components/passport/uploadDocumentsAdult/Previous";
import UserDetailsForm from "../../components/passport/userDetailsForm/UserDetailsForm";
import UploadDocumentsAdult from "../../components/passport/uploadDocumentsAdult/UploadDocumentsAdult";
import PreviewInformation from "../../components/passport/previewInformation/PreviewInformation";
import { useLocation } from "react-router-dom";

const Passport = () => {
  const routes = ["User-Details", "Upload-Documents", "Preview-Information"]
  const [route, setRoute] = useState(routes[0]);
  const location = useLocation();
  
  
  
  const handlePrevious = () => {
    let routeNumber = routes.indexOf(route);
    setRoute(routes[routeNumber - 1]); // goes back to the previous route
  }
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <section className="px-[6%] w-full" id="passport-application">
      <TabHeading
        heading="Passport"
        text="Obtain, Renew, or Replace your passports."
      />
      {route !== routes[0] &&
        <Previous text={"Previous"} handlePrevious={handlePrevious} />
      }

      {route === routes[0] &&
        <UserDetailsForm
          setRoute={setRoute}
          routes={routes}
        />
      }

      {route === routes[1] &&
        <>
          <UploadDocumentsAdult
            setRoute={setRoute}
            routes={routes}
          />
        </>
      }

      {route === routes[2] &&
        <PreviewInformation
          setRoute={setRoute}
          routes={routes}
        />
      }

    </section>
  );
}

export default Passport;

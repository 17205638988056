import React from 'react'
import { ButtonLightBorder } from '../../components/buttons/Buttons'
import NotFound from "../../assets/images/3793096-removebg-preview.png"
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="px-[6%] w-full py-[50px] md:py-[0px]">
          <div>
          <div className="w-full flex justify-center"><img src={NotFound} alt='404'/></div>
          </div>
          <p className='flex justify-center text-center text-base md:text-xl md:px-[10%] px-[0%]'>
            Seems the page you are looking for does not exist, Kindly click the button below to find your way back to the home page
          </p>
          <div className='w-full flex justify-center my-[30px]'>
            <ButtonLightBorder handleClick={() => navigate("/landing")}>
              Home
          </ButtonLightBorder>
          </div>
    </div>
  )
}

export default PageNotFound
import Hero from "../../components/home/hero/Hero";
import Navbar from "../../components/navbar/Navbar";
import TopDestinations from "../../components/home/topdestinations/TopDestinations";
import WhyChooseOgaVisa from "../../components/home/whychooseogavisa/WhyChooseOgaVisa";
import Works from "../../components/home/works/Works";
import "./Home.css";
import Reviews from "../../components/home/reviews/Reviews";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const Home = ({ setOverlay }) => {
    const location = useLocation();
    const isHome = location.pathname === '/landing';
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

        return (
            <section>
                <section 
                className="bg-image-container w-full"
                >
                    <Navbar setOverlay={setOverlay} isHome={isHome}  />
                    <Hero />
                </section>
                <WhyChooseOgaVisa />
                <TopDestinations />
                <Works />
                <Reviews />
            </section>
        );

    
}
 
export default Home;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { unauth_api } from "../../services/unauth-services/axiosInstance";
import { BASE_URL } from "../../utils/constants";

const initialState = {
    id: null,
    paymentId: null,
    visaDraft: {
        status: 'idle',
        result: []
    },
    visaUpdate: {
      status: 'idle',
      result: []
    },
    pending: {
      status: 'idle',
      result: []
    },
    person: null,
};


export const visaApplication = createAsyncThunk(
  'visaApplication/visa',
  async (payload, thunkAPI) => {
    try {
      const { data } = await unauth_api.post(`${BASE_URL}/user/visa/create`, payload);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const updateVisaDraft = createAsyncThunk(
  'updateVisaDraft/visa',
  async (payload, thunkAPI) => {
    try {
      const { data } = await unauth_api.put(
        `${BASE_URL}/user/visa/update/${payload.id}`,
        payload.data
      );

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const visaPay = createAsyncThunk(
  'visaPay/visa',
  async (payload, thunkAPI) => {
    try {
      const { data } = await unauth_api.post(`${BASE_URL}/user/visa/pay`, payload);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const verifyVisaPay = createAsyncThunk(
  'verifyVisaPay/visa',
  async (payload, thunkAPI) => {
    try {
      const { data } = await unauth_api.get(`${BASE_URL}/user/visa/verify/${payload.reference}/${payload.paymentId}`);
      return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  );

export const removePerson = createAsyncThunk(
  'removePerson/visa',
  async (payload, thunkAPI) => {
    try {
      const { data } = await unauth_api.delete(`${BASE_URL}/user/visa/remove/${payload.visaId}/${payload.personId}`);
      return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const visaSlice = createSlice({
  name: 'visa',
  initialState,
  reducers: {
    clearDraft: () => {
        localStorage.removeItem("visaDraft");
        localStorage.removeItem("visaUpdate");
    },
    getOnePerson: (state, action) => {
      const draftFromStorage = JSON.parse(localStorage.getItem('visaUpdate'));
      const id = action.payload;
      state.person = draftFromStorage.persons.find((person) => {
        return person._id === id;
      });
    },
    deleteOnePerson: (state, action) => {
      const draftFromStorage = JSON.parse(localStorage.getItem('visaUpdate'));
      const id = action.payload;
      const updatedPersons = draftFromStorage.persons.filter(person => person._id !== id);
      const updatedStorage = { ...draftFromStorage, persons:updatedPersons };
      localStorage.setItem('visaUpdate', JSON.stringify(updatedStorage));
    } 
  },
  extraReducers(builder) {
    builder
      // visa application
      .addCase(visaApplication.pending, (state) => {
        state.visaDraft.status = 'loading';
      })
      .addCase(visaApplication.fulfilled, (state, action) => {
        state.visaDraft.status = 'idle';
        state.visaDraft.result = action.payload.data;
        localStorage.setItem('visaDraft', JSON.stringify(action.payload.data));
      })
      .addCase(visaApplication.rejected, (state) => {
        state.visaDraft.status = 'idle';
      })

      // update visa draft
      .addCase(updateVisaDraft.pending, (state) => {
        state.visaUpdate.status = 'loading';
      })
      .addCase(updateVisaDraft.fulfilled, (state, action) => {
        state.visaUpdate.status = 'idle';
        state.visaUpdate.result = action.payload.data;
        state.id = action.payload.data["_id"];
        localStorage.setItem('visaUpdate', JSON.stringify(action.payload.data));
      })
      .addCase(updateVisaDraft.rejected, (state) => {
        state.visaUpdate.status = 'idle';
      })
      
      //  visa pay
      .addCase(visaPay.pending, (state) => {
        state.pending.status = 'loading';
      })
      .addCase(visaPay.fulfilled, (state, action) => {
        state.pending.status = 'idle';
        state.pending.result = action.payload.data;
        state.paymentId = action.payload.data["paymentId"];
      })
      .addCase(visaPay.rejected, (state) => {
        state.pending.status = 'idle';
      })
  }
});

export const {
  clearDraft,
  getOnePerson,
  deleteOnePerson
} = visaSlice.actions;
export default visaSlice.reducer;

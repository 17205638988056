export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // return x.replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
};


let DECIMAL_SEPARATOR = '.';
let GROUP_SEPARATOR = ',';

export const formatNumber = (valString, mode = false) => {
  // return num.replace(/\B(?=(?:\d{3})+(?!\d))/g, ',')
  if (!valString) {
    return '';
  }

  if (mode) {
    let val = valString.toString();
    const parts = unFormatNumber(val).split(DECIMAL_SEPARATOR);

    var res =
      parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, GROUP_SEPARATOR) +
      (!parts[1] ? '' : DECIMAL_SEPARATOR + parts[1]);

    return res;
  } else {
    let val = valString.toString();

    const parts = unFormatNumber(val).split(DECIMAL_SEPARATOR);

    res =
      parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, GROUP_SEPARATOR) +
      (!parts[1] ? '' : DECIMAL_SEPARATOR + parts[1]);

    return res;
  }
};

export const formatNumberWithDecimal = (num) => {
  if (!num) return '';
  const [wholeNumber, decimal] = num.toString().split('.');
  if (decimal && decimal.length > 2) {
    num = `${wholeNumber}.${decimal.slice(0, 2)}`;
  }

  let parts = num.toString().split('.');
  parts[0] = formatNumber(parts[0]);
  return parts.join('.');
};

export const unFormatNumber = (val) => {
  if (!val) {
    return '';
  }
  val = val.replace(/^0+/, '');

  if (GROUP_SEPARATOR === ',') {
    return val.replace(/,/g, '');
  } else {
    return val.replace(/\./g, '');
  }
};

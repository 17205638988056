import { CopyRight } from "./CopyRight";
import { Info } from "./Info";
import { Others } from "./Others";
import "./Footer.css";

const Footer = () => {


    return (
        <footer className="bg-[#FBFBFB]">
            <section className="flex flex-wrap justify-between px-[6%] py-20">
                <Info />
                <Others />
            </section>
            <CopyRight />
        </footer>
    );
}
 
export default Footer;
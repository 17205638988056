import { PriceSummary } from "./PriceSummary"
import { DottedBorder } from "./DottedBorder"
import { GeneralInformation } from "./GeneralInformation"
import { TripInformation } from "./TripInformation"
import { TripLocation } from "./TripLocation"
import { useSelector } from "react-redux"
import LoadingSpinnerTables from "../../loader/LoadingSpinnerTables"

export const VisaTripInformation = ({ setRoute, setCurrentStep }) => {
    const { visaUpdate } = useSelector((state) => state.visa);

    if (visaUpdate.status === 'loading') {
        return (
            <section className="h-[100px] flex justify-center items-center">
                <LoadingSpinnerTables />
            </section>
        )
    } else {
        return (
            <section className="mb-32">
                <section className="mb-10">
                    <DottedBorder />
                </section>
                <section className="mt-6 flex justify-between flex-wrap space-x-4 space-y-4 w-full">
                    <section className="w-full resize-width lg:w-[700px] 2xl:w-[1000px]">
                        <TripLocation setRoute={setRoute} setCurrentStep={setCurrentStep} />
                        <div className="border border-color8 mb-6"></div>
                        <section className="overflow-x-auto w-full">
                            <TripInformation setRoute={setRoute} setCurrentStep={setCurrentStep} />
                        </section>
                        <div className="border border-color8 mb-6"></div>
                        <section className="overflow-x-auto w-full">
                            <GeneralInformation setRoute={setRoute} setCurrentStep={setCurrentStep} />
                        </section>
                        <div className="border border-color8 mb-6"></div>
                    </section>
                    <section className="w-full resize-width lg:w-fit 2xl:w-[500px]">
                        <PriceSummary />
                    </section>
                </section>
            </section>
        )
    }

}
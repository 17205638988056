import { useNavigate } from "react-router-dom";
import { ButtonDark } from "../../buttons/Buttons";
import { Content } from "./Content";
import { Heading3 } from "./Heading3";
import "./Works.css";


const Works = () => {
    const navigate = useNavigate();
    const contents = [
        {
            heading: "Visit the Ogavisa website", 
            text: "Users select their destination country and the type of visa they require."
        },
        {
            heading: "Fill out the application form", 
            text: `You'll need to provide personal information such as your name, address, passport details, and travel plans.`
        },
        {
            heading: "Submit application and make payment", 
            text: "Users submit their visa application and make the required payment for processing."
        },
        {
            heading: "Upload supporting documents", 
            text:`Depending on the type of visa you need, you may need to upload additional documents such as a passport photo, proof of e`
        },
        {
            heading: "Pay the processing fee", 
            text: ` Once you've completed the application form and uploaded your documents, you'll need to pay the processing fee. The fee varies 
            depending on the type of visa and how quickly you need it.`
        },
        {
            heading: "Wait for processing", 
            text: ` Once your application has been approved, Ogavisa will send you the electronic visa via email or have you come pick it up. You'll need
            to print out the visa and present it to immigration officials when you arrive in the country.`
        },
        {
            heading: "Receive your visa", 
            text: ` Once your application has been approved, Ogavisa will send you the electronic visa via email or have you come pick it up. You'll need
            to print out the visa and present it to immigration officials when you arrive in the country`
        }
    ];

    return (
        <>
            <section 
            className="px-[6%] mt-20 pt-20 pb-36 works"
            >
                <section className="">
                    <Heading3 text={"How Does OgaVisa Works"} />
                </section>
                { contents.map((content, id) => (
                    <Content
                    key={id} 
                    heading={content.heading} 
                    text={content.text}
                    />
                ))}
                <section className="mt-10 md:mt-20">
                    <ButtonDark handleClick={() => navigate("/visa-application")}>
                        Apply For Visa Now
                    </ButtonDark>
                </section>
            </section>
        </>
    );
}
 
export default Works;
import "./Box.css";


export const BoxMobile = ({ heading, text, img, alt }) => {
    return (
      <section
      >
        <section 
         className="border-[0.7px] border-dashed border-pending transition-all duration-300 ease-in w-[240px] xxs:w-[280px] xs:w-[350px] rounded-md pt-[50px] pl-[5%] md:pl-[3%] h-[280px] xxs:h-[270px]"
        >
          <h3 className="text-2xl sm:text-3xl mb-3 text-darkblue font-semibold text-center"> { heading } </h3>
          <section className="flex justify-center w-full">
            <img src={img} width={60} height={60} alt={alt} />
          </section>
          <p className="text-lg mx-auto font-normal text-black text-center w-full"> { text } </p>
        </section>
      </section>
    );
  };
  
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonDark } from '../../components/buttons/Buttons';

const Swit = () => {
  const navigate = useNavigate();
  return (
    <section className='max-w-3xl mx-auto my-4'>
      <h1 className=" text-darkblue font-bold text-4xl text-center lg:text-left md:text-6xl font-playfair"> Swit </h1>
      <section className='text-base sm:text-lg text-color4 text-center lg:text-left font-normal mt-3 '>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Visa Requirements:
          </p>
          <p>
            Most visitors to Switzerland, regardless of their nationality, need a visa to enter the country. 
            However, visitors from certain countries, such as citizens of the European Union (EU), the European Economic Area (EEA), and Switzerland, 
            do not need a visa for short-term stays of up to 90 days. 
            Visitors from other countries need to apply for a Schengen visa, which is a short-stay visa that allows travelers to visit Switzerland and 
            other Schengen countries for up to 90 days within a 180-day period.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Visa Application Process:
          </p>
          <p>
            If you need to apply for a visa to travel to Switzerland, you can do so at a Swiss consulate or embassy in your home country. 
            You will need to fill out a visa application form, provide supporting documents, such as a passport, travel itinerary, and proof of sufficient funds, 
            and pay the visa fee. The processing time for a visa application can vary, but it usually takes around 5 to 15 working days.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Passport Requirements:
          </p>
          <p>
            Visitors to Switzerland need a passport that is valid for at least three months beyond the planned departure date. 
            Some countries may also require a visa or other travel documents.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
              Passport Application Process:
          </p>
          <p>
            If you need to apply for a passport, you can do so at your local passport office or online. 
            You will need to provide proof of identity, such as a birth certificate or driver's license, and two passport-sized photos. 
            The processing time for a passport application can vary, but it usually takes around 2 to 4 weeks.
          </p>
        </p>
  
        <section className="flex justify-center mt-8">
          <ButtonDark handleClick={() => navigate("/visa-application")}>
            Apply For Visa Now
          </ButtonDark>
        </section>
          

      </section>




    </section>
  );
}

export default Swit
import { createColumnHelper } from "@tanstack/react-table";
import { useDispatch } from "react-redux";
import { RxAvatar } from "react-icons/rx";
import { OnlyDate } from "../../excerpts/OnlyDate";
import { PaginatedTable } from "../../table/PaginatedTable";
import { deleteOnePerson, getOnePerson, removePerson } from "../../../features/unauth-features/visaSlice";
import { useEffect, useState } from "react";
import { ErrorToast, SuccessToast } from "../../toast/toasts";

const PersonsTable = () => {
    const dispatch = useDispatch();
    const [ persons, setPersons ] = useState();
    const [ id, setId ] = useState();
    useEffect(() => {
        const updateFromStorage = JSON.parse(localStorage.getItem('visaUpdate'));
        if (updateFromStorage) {
            setPersons(updateFromStorage.persons);
            setId(updateFromStorage._id);
        }
    }, []);


    const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor('_id', {
        id: "edit",
        cell: (info) => (
            <span 
            onClick={() => {
                dispatch(getOnePerson(info.getValue()))
            }}
            className="text-pending font-medium text-sm pr-6 cursor-pointer"
            > 
                Edit 
            </span>
    ),
        header: () => <span>  </span>,
    }),
    columnHelper.accessor('_id', {
        id: "delete",
        cell: (info) => (
            <span 
            onClick={() => {
                const payload = {
                    visaId: id,
                    personId: info.getValue()
                }
                dispatch(removePerson(payload))
                .unwrap()
                .then((res) => {
                    dispatch(deleteOnePerson(info.getValue()))
                    const updateFromStorage = JSON.parse(localStorage.getItem('visaUpdate'));
                    setPersons(updateFromStorage.persons);
                    SuccessToast("Person deleted successfully");
                })
                .catch((err) => {
                    ErrorToast(err?.data?.error || "Error occurred");
                })
            }}
            className="text-[#DC143C] font-medium text-sm pr-6 cursor-pointer"
            > 
                Delete 
            </span>
    ),
        header: () => <span>  </span>,
    }),
    columnHelper.accessor(row => `${row.surname},${row.firstAndMiddleName}`, {
        id: 'profile',
        cell: (info) => (
            <section className={`flex items-center`}>
                <section className="rounded-[200px] w-[32px] h-[32px]">
                    <RxAvatar />
                </section>
                <section className={`ml-2`}>
                    <p className="text-sm text-gray-900 font-medium">{ info.getValue().split(',')[0] }</p>
                    <p className="text-sm font-normal text-gray-500">{ info.getValue().split(',')[1] }</p>
                </section>
            </section>
        ),
        header: () => <span>Name</span>,
    }),
    columnHelper.accessor('nationality', {
        cell: (info) => (
            <section className={``}>
                <p className="text-sm font-normal text-color6"> {info.getValue()} </p>
            </section>
            ),
        header: () => <span>Nationality</span>,
    }),
    columnHelper.accessor('dob', {
        cell: (info) => (
            <section className={``}>
                <p className="text-sm font-normal text-color6">  <OnlyDate date={info.getValue()} /> </p>
            </section>
        ),
        header: () => <span>Date of Birth</span>,
    }),
    columnHelper.accessor('passportNumber', {
        cell: (info) => (
            <section className={``}>
                <p className="text-sm font-normal text-color6"> {info.getValue()} </p>
            </section>
        ),
        header: () => <span>Passport Number</span>,
    }),
];

    return (
        <>
        { persons &&
            <PaginatedTable data={persons} columns={columns} filter={false} paginate={false}/>
        }
        </>
    );
}
 
export default PersonsTable;
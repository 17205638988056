import { useState } from "react";

const SearchTab = ({ setGlobalFilter }) => {
    const [ active, setActive ] = useState("All");
    const status =  ["All", "Approved", "Pending", "Declined"];
    const handleClick = (item) => {
        switch (item) {
            case "All":
                setGlobalFilter("");
                setActive(item);
                break;
            case "Approved":
                setGlobalFilter("Approved");
                setActive(item);
                break;
            case "Pending":
                setGlobalFilter("Pending");
                setActive(item);
                break;
            case "Declined":
                setGlobalFilter("Declined");
                setActive(item);
                break;
            default:
                break;
        }

    }
    return (
        <section className="border border-gray-300 text-darkblue text-[8px] xxs:text-[10px] xs:text-sm font-medium flex h-fit rounded-lg p-0.5">
            { status.map(item => (
                <p className={`py-2 px-3 cursor-pointer ${item === "All" ? "" : "border-l" } h-fit ${active === item && "bg-tab"} `} onClick={() => handleClick(item)}> {item} </p>
            ))}
        </section>
    );
}
 
export default SearchTab;
import React from "react";

const UserDetails = ({ heading, button, userDetail, }) => {
  return (
    <section className="">
      <section className="heading flex justify-between">
        <section className="title flex gap-x-1 text-2xl text-pending">
          {heading}
        </section>
        <section className="edit">{button}</section>
      </section>
      <ul className="my-[30px] grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-[30px]">
        {userDetail.map((item, id) => (
          <li key={id}>
            <p className="text-color3 font-normal">{item.title}:</p>
            <p className="text-black mt-1.5 font-medium break-words"><strong>{item.value}</strong></p>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default UserDetails;

import { useDispatch, useSelector } from "react-redux";
import { ButtonLargeDark } from "../../buttons/Buttons";
import "./VisaTripInformation.css";
import { PaystackConsumer } from 'react-paystack';
import { verifyVisaPay, visaPay } from "../../../features/unauth-features/visaSlice";
import Amount from "../../excerpts/Amount";
import { useNavigate } from "react-router-dom";
import { RandomGen } from "../../excerpts/RandomGen";
import { clearStorageVisa } from "../../../features/unauth-features/generalSlice";



export const PriceSummary = () => {
    const { visaUpdate, id, } = useSelector((state) => state.visa);
    const dispatch = useDispatch();
    const amount = 50000;
    const persons = visaUpdate?.result?.persons.length;
    const total = amount * persons;
    const navigate = useNavigate();
    const randomIntegers = RandomGen(10, 1, 100);
    
    const config = {
        reference:`OGAVISA-${randomIntegers}`,
        email: visaUpdate?.result?.email,
        amount: total * 100, // Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: 'pk_live_7efc966a137a0f151c9c97ef11fd2948a1e007a1',
    };
      // you can call this function anything
    const handleSuccess = () => {
            
            const payload = {
                reference: localStorage.getItem("reference"),
                paymentId: localStorage.getItem("paymentId"),
            }
            dispatch(verifyVisaPay(payload))
            .unwrap()
            .then((res) => {
                navigate("/payment-confirmation");
                localStorage.removeItem("reference");
                localStorage.removeItem("paymentId");
                dispatch(clearStorageVisa());
            })
            .catch((err) => {
                console.log(err);
            })
    // Implementation for whatever you want to do with reference and after success call.
    };
    
    // you can call this function anything
    const handleClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
    }
    
    const componentProps = {
        ...config,
        // text: 'Paystack Button Implementation',
        onSuccess: (reference) => handleSuccess(reference),
        onClose: handleClose
    };

    const handleVisaPay = (callback) => {
        const payload = {
            amount: total,
            reference: config.reference,
            currency: "NGN",
            visaId: id,
        }
        dispatch(visaPay(payload))
        .unwrap()
        .then((res) => {
            localStorage.setItem("reference", res.data.reference);
            localStorage.setItem("paymentId", res.data.paymentId);
            callback();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    
    return (
        <section className="shadow-[0px_0px_75px_rgba(0,0,0,0.05)] summary sm:w-[300px] mx-auto h-fit p-4 sm:p-7">
            <h3 className="text-darkblue font-semibold text-xl rounded-[5px]"> Your Price Summary </h3>
            <section className="mt-4 p-5 border text-base border-color9  font-normal text-black flex flex-col space-y-4 rounded-[5px]">
                <section className="flex justify-between">
                    <span> Service Fee </span>
                    <span> <Amount amount={amount} /> </span>
                </section>
                
                <section className="flex justify-between">
                    <span> Number of Travellers </span>
                    <span> { persons } </span>
                </section>
                
                <section className="flex justify-between">
                    <span> Total </span>
                    <span> <Amount amount={total} /> </span>
                </section>
            </section>
            

            <section className="mt-6 mx-auto">
            <PaystackConsumer {...componentProps} >
                {
                    ({initializePayment}) => 
                    <ButtonLargeDark handleClick={() => {
                        handleVisaPay(initializePayment(handleSuccess, handleClose));
                    }}
                    >
                        Proceed to Payment
                    </ButtonLargeDark>
                }
            </PaystackConsumer>
            </section>
        </section>
    )
}
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const CountriesMobile = ({data}) => {
    return (
        <section className="mt-10 md:hidden">
             <Swiper
                slidesPerView={1}
                spaceBetween={30}
                pagination={{
                clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper mt-10"
                >
                { data.map((info, id) => (
                    <SwiperSlide>
                        <div
                        key={id}
                        className={`flex items-end 
                        bg-no-repeat bg-cover bg-center cursor-pointer w-full h-[250px] transition-all duration-300 ease-in-out rounded-md`}
                        style={{backgroundImage: `url(${info.img})`}}
                        >
                            <Link to={info.link} className=" h-full w-full flex items-end ">
                                <p 
                                className="ml-[9%] mb-[9%] text-white font-semibold text-xl md:text-2xl flex items-center"
                                >
                                    {info.name} <img className="ml-2" src={info.icon} width={35} height={35} alt={info.name} /> 
                                </p>
                            </Link>
                        </div>
                    </SwiperSlide>
                ))} 
                </Swiper>
        </section>
    );
}
 
export default CountriesMobile;
import ReactPaginate from "react-paginate";
import Table from "./Table";
import { useEffect, useState } from "react";
import { NextLabel, PreviousLabel } from "./Label";
import "./PaginatedTable.css";

export const PaginatedTable = ({ data, columns, filter, paginate }) => {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 6;

  useEffect(() => {
    // Fetch items from another resources.
    if(data) {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / itemsPerPage));
    }
  }, [itemOffset, itemsPerPage, data]);


  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage % data.length;
    setItemOffset(newOffset);
  };

  return (
    <section>
      <Table data={currentItems} columns={columns} filter={filter} />
      { paginate && <div className="h-[1px] bg-[#E4E7EC] mt-6"></div>}
      { paginate &&
          <ReactPaginate
          breakLabel="..."
          breakClassName="break-class"
          breakLinkClassName="break"
          nextLabel={<NextLabel />}
          previousLabel={<PreviousLabel />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      }
    </section>
  );
}
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonDark } from '../../components/buttons/Buttons';

const Uk = () => {
  const navigate = useNavigate();
  return (
    <section className='max-w-3xl mx-auto my-4'>
      <h1 className=" text-darkblue font-bold text-4xl text-center lg:text-left md:text-6xl font-playfair"> Uk </h1>
      <section className='text-base sm:text-lg text-color4 text-center lg:text-left font-normal mt-3 '>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Visa Requirements:
          </p>
          <p>
            Visa requirements for the UK depend on the nationality of the visitor. 
            Visitors from some countries, such as the European Union (EU) and the European Economic Area (EEA), do not need a visa for short-term stays of up to six months. 
            Visitors from other countries need to apply for a visa to enter the UK.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Visa Application Process:
          </p>
          <p>
            If you need to apply for a visa to travel to the UK, you can do so online or in person at a visa application center. 
            You will need to fill out a visa application form, provide supporting documents, such as a passport, travel itinerary, and proof of sufficient funds, 
            and pay the visa fee. 
            The processing time for a visa application can vary depending on the type of visa you are applying for, but it usually takes around 3 weeks.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
            Passport Requirements:
          </p>
          <p>
            Visitors to the UK need a passport that is valid for at least six months beyond the planned departure date. Some countries may also require a visa or other travel documents.
          </p>
        </p>
        <p className='mt-3'>
          <p className='text-darkblue font-medium'>
              Passport Application Process:
          </p>
          <p>
            If you need to apply for a passport, you can do so online or in person at a passport office. You will need to provide proof of identity, such as a birth certificate or driver's license, 
            and two passport-sized photos. 
            The processing time for a passport application can vary depending on the type of passport you are applying for, but it usually takes around 3 to 6 weeks.
          </p>
        </p>
 
        <section className="flex justify-center mt-8">
          <ButtonDark handleClick={() => navigate("/visa-application")}>
            Apply For Visa Now
          </ButtonDark>
        </section>
          

      </section>




    </section>
  );
}

export default Uk
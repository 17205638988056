import { Controller } from "react-hook-form";
import Select from "react-select";
import { ErrorMsg } from "../../alert/ErrorMsg";
import { InputWrap, labelStyling } from "./InputStyles";



export const CustomSelect = ({ name, method, options, label, placeholder, icon, selected, ...rest }) => {

  const {
    control,
    formState: { errors },
  } = method;
  
  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
    })
  };

  return (
    <>
      <InputWrap>
        <label className="flex items-center" style={labelStyling}> { label } { icon } </label>
        <Controller
        control={control}
        render={({ field: { onChange, value, name, ref } }) => {
          const currentSelection = options.find((c) => c.value === value);
          const handleSelectChange = (selectedOption) => {
            onChange(selectedOption?.value);
          };
          return (
            <Select
              name={name}
              inputRef={ref}
              value={value ? currentSelection : []}
              placeholder={placeholder}
              autoFocus={false}
              defaultValue={""}
              onChange={handleSelectChange}
              options={options}
              className="custom-select focus:outline-none"
              hideSelectedOptions
              styles={customStyles}
              {...rest}
            />
          );
        }}
        name={name}
        rules={{ required: true }}
        />
        { errors[name] && <ErrorMsg msg={errors[name].message} /> }
      </InputWrap>
    </>
  );
};

import { HiArrowNarrowLeft } from "react-icons/hi"

export const Previous = ({text, handlePrevious}) => {
    return (
        <div onClick={handlePrevious} className="cursor-pointer prev text-base items-center flex mt-[20px]">
            <HiArrowNarrowLeft className="text-color-5" />
            <span
                className="ml-[10px] text-color5 font-normal"
            >
                {text}
            </span>
        </div>
    )
}
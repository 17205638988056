import { useState } from "react";
import { RightImageWithText } from "../../components/login/RightImageWithText";
import { Top } from "../../components/login/Top";
import { VerifyEmail } from "../../components/login/VerifyEmail";
import { WelcomeBack } from "../../components/login/WelcomeBack";

const Login = () => {
    const [ step, setStep ] = useState('welcome');
    return (
        <section className="h-[100vh] border border-black">
            <section className="flex h-full">
                <section className="w-full md:w-[60%] lg:w-[60%] px-[6%]">
                    <Top />
                    { step === "verify" &&  <VerifyEmail setStep={setStep} /> }
                    { step === "welcome" &&  <WelcomeBack setStep={setStep} /> }
                </section>
                <RightImageWithText />
            </section>

        </section>
    );
}
 
export default Login;

import { Boxes } from "./Boxes";
import { Heading2 } from "./Heading2";
import "./WhyChooseOgaVisa.css";

const WhyChooseOgaVisa = () => {
    return (
        <section className="px-[6%] mt-32">
            <Heading2 text={"Why Choose OgaVisa?"} />
            <Boxes />
        </section>
    );
}
 
export default WhyChooseOgaVisa;
import { Link } from "react-router-dom";

const MoreCountries = () => {
    const countries = [
        {name: "Germany", path: "/germany"},
        { name: "Switzerland", path: "/swit"},
        { name: "United States", path:"/usa"},
        { name: "United Kingdom", path: "/uk"},
        { name: "France", path: "/france"} ,
        { name: "Japan", path: "/japan"},
        { name: "Italy", path: "/italy"},
        { name: "Portugal", path: "/portugal"},
        { name: "Canada", path: "/canada"},
        { name: "Spain", path: "/spain"},
        { name: "China", path: "/china"},
        { name: "South Africa", path: "/south-africa"},
        { name: "Mexico", path: "/mexico"},
        { name: "Brazil", path: "/brazil"},
        { name: "Ireland", path: "/ireland"},
        { name: "New Zealand", path: "/newzealand"},
        { name: "Dubai", path: "/dubai"},
        { name: "Egypt", path: "/egypt"},
        { name: "Australia", path: "/australia"}
    ];
    return (
        <section className="w-full shadow-md p-5 mt-8">
            <section className="grid justify-items-center w-full gap-4 my-4 mx-auto"
            style={{
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
            }}
            >
                {
                    countries.map((country) => (
                        <div>
                            <Link to={country.path}>
                                <p className="text-darkblue text-xl font-medium"> { country.name } </p>
                            </Link>
                        </div>
                    ))
                }
            </section>
        </section>
    );
}
 
export default MoreCountries;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { unauth_api } from "../../services/unauth-services/axiosInstance";
import { BASE_URL } from "../../utils/constants";

const initialState = {
    otpInfo: {
        status: 'loading',
        result: []
    },
    user: {
        status: 'loading',
        result: []
    },
    token: null,
};


export const requestOTP = createAsyncThunk(
  'requestOTP/login',
  async (payload, thunkAPI) => {
    try {
      const { data } = await unauth_api.post(`${BASE_URL}/user/auth/otp`, payload);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);


export const userLogin = createAsyncThunk(
  'userLogin/login',
  async (payload, thunkAPI) => {
    try {
      const { data } = await unauth_api.post(`${BASE_URL}/user/auth/login`, payload);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);


export const userLogout = createAsyncThunk(
  'userLogout/login',
  async (_, thunkAPI) => {
    try {
      const { data } = await unauth_api.delete(`${BASE_URL}/user/auth/logout`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);


const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    clearToken() {
      localStorage.removeItem('ogaVisaToken');
      window.location.href = '/';
    }
  },
  extraReducers(builder) {
    builder
      // otp request
      .addCase(requestOTP.pending, (state) => {
        state.otpInfo.status = 'loading';
      })
      .addCase(requestOTP.fulfilled, (state, action) => {
        state.otpInfo.status = 'idle';
        state.otpInfo.result = action.payload.data;
      })
      .addCase(requestOTP.rejected, (state) => {
        state.otpInfo.status = 'idle';
      })
      // user login
      .addCase(userLogin.pending, (state) => {
        state.user.status = 'loading';
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.user.status = 'idle';
        state.user.result = action.payload.data;
        state.token = action.payload.token;
        localStorage.setItem('ogaVisaToken', action.payload.token);
      })
      .addCase(userLogin.rejected, (state) => {
        state.user.status = 'idle';
      })
  }
});

export const { clearToken } = loginSlice.actions;
export default loginSlice.reducer;

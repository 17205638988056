import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { unauth_api } from "../../services/unauth-services/axiosInstance";
import { BASE_URL } from "../../utils/constants";

const initialState = {
    id: null,
    paymentId: null,
    passportDraft: {
        status: 'idle',
        result: []
    },
    passportUpdate: {
      status: 'idle',
      result: []
    },
    pending: {
      status: 'idle',
      result: []
    },
};


export const passportApplication = createAsyncThunk(
  'passportApplication/passport',
  async (payload, thunkAPI) => {
    try {
      const { data } = await unauth_api.post(`${BASE_URL}/user/passport/create`, payload);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const updatePassportDraft = createAsyncThunk(
  'updatePassportDraft/passport',
  async (payload, thunkAPI) => {
    try {
      const { data } = await unauth_api.put(
        `${BASE_URL}/user/passport/update/${payload.id}`,
        payload.data
      );

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);


export const passportPay = createAsyncThunk(
  'passportPay/passport',
  async (payload, thunkAPI) => {
    try {
      const { data } = await unauth_api.post(`${BASE_URL}/user/passport/pay`, payload);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const verifypassportPay = createAsyncThunk(
  'verifypassportPay/passport',
  async (payload, thunkAPI) => {
    try {
      const { data } = await unauth_api.get(`${BASE_URL}/user/passport/verify/${payload.reference}/${payload.paymentId}`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);


export const removeFile = createAsyncThunk(
  'removeFile/passport',
  async (payload, thunkAPI) => {
    try {
      const { data } = await unauth_api.delete(`${BASE_URL}/user/passport/remove/${payload.passportId}/${payload.fileId}`);
      return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const passportSlice = createSlice({
  name: 'passport',
  initialState,
  reducers: { 
    deleteOneFile: (state, action) => {
      const draftFromStorage = JSON.parse(localStorage.getItem('passportUpdate'));
      const id = action.payload;
      const updatedFiles = draftFromStorage.documentFile.filter(item => item._id !== id);
      const updatedStorage = { ...draftFromStorage, documentFile: updatedFiles };
      localStorage.setItem('passportUpdate', JSON.stringify(updatedStorage));
    }
  },
  extraReducers(builder) {
    builder
      // passport application
      .addCase(passportApplication.pending, (state) => {
        state.passportDraft.status = 'loading';
      })
      .addCase(passportApplication.fulfilled, (state, action) => {
        state.passportDraft.status = 'idle';
        state.passportDraft.result = action.payload.data;
        localStorage.setItem('passportDraft', JSON.stringify(action.payload.data));
      })
      .addCase(passportApplication.rejected, (state) => {
        state.passportDraft.status = 'idle';
      })
      // update passport draft
      .addCase(updatePassportDraft.pending, (state) => {
        state.passportUpdate.status = 'loading';
      })
      .addCase(updatePassportDraft.fulfilled, (state, action) => {
        state.passportUpdate.status = 'idle';
        state.passportUpdate.result = action.payload.data;
        state.id = action.payload.data["_id"];
        localStorage.setItem('passportUpdate', JSON.stringify(action.payload.data));
      })
      .addCase(updatePassportDraft.rejected, (state) => {
        state.passportUpdate.status = 'idle';
      })
      
      //  visa pay
      .addCase(passportPay.pending, (state) => {
        state.pending.status = 'loading';
      })
      .addCase(passportPay.fulfilled, (state, action) => {
        state.pending.status = 'idle';
        state.pending.result = action.payload.data;
        state.paymentId = action.payload.data["paymentId"];
      })
      .addCase(passportPay.rejected, (state) => {
        state.pending.status = 'idle';
      })
  }
});

export const {
  deleteOneFile
} = passportSlice.actions;
export default passportSlice.reducer;

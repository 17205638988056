import { useState } from "react";
import logo from "../../assets/images/revised_logo.png"
import { ButtonLightBorder } from "../buttons/Buttons";
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoArrowBackOutline } from 'react-icons/io5';
import "./Navbar.css";
import { Link, NavLink } from "react-router-dom";
import { UserWithIcon } from "../user/user-with-icon/UserWithIcon";
import { TOKEN } from "../../utils/token";

const links = [
    { name: "Home", activeName: "home", url: "/landing", id: "landing" },
    { name: "Visa Services", activeName: "visa", url: "/visa-application", id: "home" },
    { name: "Passport", activeName: "passport", url: "/passport-application", id: "visa" }
];


const Navbar = ({ isHome, setOverlay, }) => {
    const [ open, setOpen ] = useState(false);

    const handleOpen = () => {
        setOpen(true);
        setOverlay(true);
    }

    const handleClose = () => {
        setOpen(false);
        setOverlay(false);
    }

    return (
        <>
            <nav 
            className={
            `hidden md:flex justify-between w-full px-[6%] py-4 ${isHome ? 'bg-transparent' : 'bg-white shadow-md' }`}
            >
                <section>
                    <Link to={"/landing"}>
                        <img src={logo} className="w-[75px] h-[75px]" alt="brand-logo" />
                    </Link>
                </section>
                <section className="flex justify-center items-center ">
                    <ul className="text-black text-lg flex justify-center items-center nav">
                        { links.map((link, id) => (
                            <>
                                <NavLink key={link.id} to={link.url} onClick={() => setOverlay(false)}>
                                    <li 
                                    className={`
                                    transition-all ease-in-out cursor-pointer`}
                                    > 
                                        {link.name} 
                                    </li>
                                </NavLink>
                                <div key={id} className="w-[30px]"></div>
                            </>
                        )) }
                    </ul>
                    <section>
                        { TOKEN &&
                            <UserWithIcon />
                        }
                        { !TOKEN &&  
                            <Link to={"/login"}>
                                <ButtonLightBorder isHome={isHome}>
                                    Login
                                </ButtonLightBorder>
                            </Link>
                        }
                    </section>
                </section>
            </nav>

            <nav 
            className={`
            flex items-center w-full md:hidden justify-between px-[4%] py-4 ${isHome ? 'bg-transparent' : 'bg-white shadow-md' } `}
            >
                <section>
                    <GiHamburgerMenu className="text-darkblue text-2xl" onClick={handleOpen} />
                </section>
                
                <section className="w-12 s:w-14">
                    <img src={logo} alt="brand-logo" className="w-fit" />
                </section>
                
                <section>
                    { TOKEN &&
                        <UserWithIcon />
                    }
                    { !TOKEN &&  
                        <Link to={"/login"}>
                            <ButtonLightBorder isHome={isHome}>
                                Login
                            </ButtonLightBorder>
                        </Link>
                    }
                </section>

                <aside 
                className={`${open ? 'w-[40%] s:w-[30%]' : 'w-0'}  h-full md:hidden fixed left-0 top-0 bg-white shadow-lg z-[1000] 
                transition-all duration-300`}
                >
                    <section className="w-full flex justify-start h-fit pl-3 mt-10">
                        <IoArrowBackOutline className="text-xl" onClick={handleClose} />
                    </section>
                    <ul className={`${open ? 'flex fade-in-text' : 'hidden'} flex-col w-full h-full pl-3 mt-10`}>
                        { links.map((link, id) => (
                        <>
                            <NavLink key={link.id} to={link.url} onClick={() => setOverlay(false)}>
                                <li> {link.name} </li>
                            </NavLink>
                            <div key={id} className="h-[30px]"></div>
                        </> 
                        ))}
                    </ul>
                </aside>

            </nav>
        </>
    );
}
 
export default Navbar;
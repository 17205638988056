export const Heading = ({ heading, text, email }) => {
    return (
        <section className="text-center mb-5 mt-10">
            <h2 
            className="text-darkblue font-semibold mb-5 text-3xl md:text-5xl"
            > 
                { heading } 
            </h2> 
            { email && 
            <p className="font-normal text-xl text-color3 mt-2"
            > 
                { text } <span className="font-bold text-darkblue"> { email } </span> 
            </p>}
            { !email && <p className="font-normal text-xl text-color3 mt-2"> { text } </p>}
        </section>
    )
}
import { Text } from "./Text";
import { Welcome } from "./Welcome";

const Hero = () => {
    return (
        <section className="w-full xxxs:px-1 px-[4%] sm:px-[6%]">
            <Text />
            <Welcome />
        </section>
    );
}
 
export default Hero;
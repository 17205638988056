import React from 'react'

const PrivacyPolicy = () => {
  return (
    <section className='px-[6%] my-4' id='privacy-policy'>
      <h1 className=" text-darkblue font-bold text-3xl text-center lg:text-left md:text-5xl font-playfair"> Privacy Policy </h1>
      <ol className='text-base sm:text-lg text-color4 text-center lg:text-left font-normal mt-5 '>
        <li className='mt-5'>
          <h2 className='text-darkblue font-medium'>
            Personal information:
          </h2>
           <p>
             Ogavisa will only collect personal information that is necessary to process your visa application. This may include your name, address, passport details, and other travel information.
           </p>
        </li>
        <li className='mt-5'>
          <h2 className='text-darkblue font-medium'>
            Consent:
          </h2>
           <p>
            OgaVisa will obtain your consent before collecting, using, or disclosing your personal information. You should be informed about the purposes for which your information will be used and who it may be shared with.
           </p>
        </li>
        <li className='mt-5'>
          <h2 className='text-darkblue font-medium'>
            Security:
          </h2>
           <p>
            OgaVisa will take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. This may include using encryption and other security technologies to safeguard your data.
           </p>
        </li>
        <li className='mt-5'>
          <h2 className='text-darkblue font-medium'>
            Sharing:
          </h2>
           <p>
            OgaVisa may need to share your personal information with government agencies or other third-party service providers to process your visa application. They should only do so with your consent or as required by law.
           </p>
        </li>
        <li className='mt-5'>
          <h2 className='text-darkblue font-medium'>
            Retention:
          </h2>
           <p>
            OgaVisa will only retain your personal information for as long as necessary to fulfill the purposes for which it was collected. 
           </p>
        </li>
      </ol>
      <p className="text-base sm:text-lg text-color3 text-center lg:text-left font-normal mt-5">
        We also have policies in place for securely disposing of your information when it is no longer needed.
      </p>
      <p className="text-base sm:text-lg text-color3 text-center lg:text-left font-normal mt-5">
        It's important to read and understand the privacy policy of our visa processing company before using the services. If you have any questions or concerns about how OgaVisa collects, uses, or discloses your personal information, you should contact their customer support team for assistance.
      </p>

    </section>
  );
}

export default PrivacyPolicy
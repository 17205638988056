import client1 from "../../../assets/images/black2.jpg";
import client2 from "../../../assets/images/client2.jpg";
import client3 from "../../../assets/images/black3.jpg";
import client4 from "../../../assets/images/black.jpg";

export const CircleWithImage = () => {
    return (
        <section className="h-[400px] w-[400px] mx-auto bg-transparent border-r-2 border-color2 border-dashed rounded-t-full rounded-r-full mt-16">
            <div 
            className="w-[100px] h-[100px] rounded-full -translate-x-10 -translate-y-10 mx-auto drop-shadow-[0_0px_41px_rgba(0,0,0,0.25)]"
            style={{
                background: `url(${client1}) no-repeat`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}
            ></div>
            <section className="flex justify-end items-center">
                <div 
                className="w-[140px] h-[140px] rounded-full drop-shadow-[0_0px_41px_rgba(0,0,0,0.25)]"
                style={{
                    background: `url(${client4}) no-repeat`,
                    backgroundSize: "cover",
                    backgroundPosition: "center"

                }}
                ></div>
                <div 
                className="w-[100px] h-[100px] rounded-full translate-x-10 drop-shadow-[0_0px_41px_rgba(0,0,0,0.25)]"
                style={{
                    background: `url(${client2}) no-repeat`,
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                }}
                ></div>
            </section>
            <div 
            className="w-[100px] h-[100px] rounded-full mx-auto -translate-x-10 translate-y-24 drop-shadow-[0_0px_41px_rgba(0,0,0,0.25)]"
            style={{
                background: `url(${client3})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}
            ></div>
        </section>
    )
};
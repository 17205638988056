import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth_api } from '../../services/auth-services/axiosInstance';
import { BASE_URL } from "../../utils/constants";

const initialState = {
    userInfo: {
        status: 'loading',
        result: [],
    },
    userApplication: {
        status: 'loading',
        result: [],
    },
    activeTab: "profile"
};


export const userProfile = createAsyncThunk(
  'userProfile/user',
  async (_, thunkAPI) => {
    try {
      const { data } = await auth_api.get(`${BASE_URL}/user/dashboard/profile`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getUserApplication = createAsyncThunk(
  'userApplication/user',
  async (_, thunkAPI) => {
    try {
      const { data } = await auth_api.get(
        `${BASE_URL}/user/dashboard/all`,
      );

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      // get user profile
      .addCase(userProfile.pending, (state) => {
        state.userInfo.status = 'loading';
      })
      .addCase(userProfile.fulfilled, (state, action) => {
        state.userInfo.status = 'idle';
        state.userInfo.result = action.payload.data;
      })
      .addCase(userProfile.rejected, (state) => {
        state.userInfo.status = 'idle';
      })
      // get user applications
      .addCase(getUserApplication.pending, (state) => {
        state.userApplication.status = 'loading';
      })
      .addCase(getUserApplication.fulfilled, (state, action) => {
        state.userApplication.status = 'idle';
        state.userApplication.result = action.payload.application;
      })
      .addCase(getUserApplication.rejected, (state) => {
        state.userApplication.status = 'idle';
      })
  }
});

export const {
  setActiveTab,
} = userSlice.actions;
export default userSlice.reducer;

import login from "../../assets/images/login.webp";

export const RightImageWithText = () => {
    return (
        
        <section 
        className="hidden md:w-[40%] md:flex items-end"
        style={{
            background: `url(${login}) no-repeat`,
            backgroundSize: "cover",
            backgroundPosition: "left"

        }}
        >
            <section className="w-fit px-[6%] mb-5">
                <p className="text-base font-normal text-white"> 
                    “I was very impressed with the speed and efficiency of Ogavisa's processing times. I received my 
                    visa in record time.” 
                </p>
                <p className="font-medium text-xl text-pending mt-2"> Johnson James </p>
            </section>
        </section>
    )
}
import { Tab } from "../../components/tab/Tab";
import { ApplicationStatus } from "../../components/user/ApplicationStatus";
import { Heading } from "../../components/user/Heading";
import { Profile } from "../../components/user/Profile";
import { useSelector } from "react-redux";

const User = () => {
    const { activeTab } = useSelector((state) => state.user);
    const tabList = [
        {name: 'Profile', alias: 'profile'},
        {name: 'Application Status', alias: 'application-status'}
    ]

    return (
        <section className="">
            <Heading />
            <Tab 
            tabs={tabList} 
            />
            { activeTab === tabList[0].alias && <Profile /> }
            { activeTab === tabList[1].alias && <ApplicationStatus /> }
        </section>
    );
}
 
export default User;
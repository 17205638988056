import axios from 'axios';
import { status403 } from '../../utils/domain';
import { TOKEN } from '../../utils/token';

const auth_api = axios.create();
// Request interceptor for API calls for authenticated routes
auth_api.interceptors.request.use(
  async (config) => {
    config.headers = {
        Authorization: `Bearer ${TOKEN}`,
        'Content-Type': 'application/json',
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);


// Response interceptor for API calls for authenticated routes
auth_api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response.status === 403) {
        status403();
    }
    return Promise.reject(error);
  }
);

export { auth_api };

import { Heading2 } from "../whychooseogavisa/Heading2";
import { Countries } from "./Countries";
import "./TopDestinations.css";
import germany from "../../../assets/images/Germany.jpg";
import switzerland from "../../../assets/images/Swiss.jpg";
import france from "../../../assets/images/France.jpg";
import uk from "../../../assets/images/Uk.jpg";
import america  from "../../../assets/images/America.jpg";
import japan  from "../../../assets/images/Japan.jpg";
import italy  from "../../../assets/images/Italy.jpg";
import portugal  from "../../../assets/images/Portugal.jpg";
import canada  from "../../../assets/images/Canada.jpg";
import austria  from "../../../assets/images/Austria.jpg";
import icon_america  from "../../../assets/icons/icon-us.png";
import icon_germany  from "../../../assets/icons/icon-germany.png";
import icon_uk  from "../../../assets/icons/icon-uk.png";
import icon_swiss  from "../../../assets/icons/icon-switzerland.png";
import icon_france  from "../../../assets/icons/icon-france.png";
import icon_canada  from "../../../assets/icons/icon-canada.png";
import icon_italy  from "../../../assets/icons/icon-italy.png";
import icon_japan  from "../../../assets/icons/icon-japan.png";
import icon_portugal  from "../../../assets/icons/icon-portugal.png";
import icon_austria  from "../../../assets/icons/icon-austria.png";
import { ButtonDarkBorder } from "../../buttons/Buttons";
import { useState } from "react";
import MoreCountries from "./MoreCountries";
import CountriesMobile from "./CountriesMobile";


const TopDestinations = () => {
    const [more, setMore] = useState(false);

    const countryInfo = [
        { name: "Germany", link: "/germany", img: germany, icon: icon_germany },
        { name: "Switzerland", link: "/swit", img: switzerland, icon: icon_swiss },
        { name: "United States of America", link: "/usa", img: america, icon: icon_america },
        { name: "United Kingdom", link: "/uk", img: uk, icon: icon_uk },
        { name: "France", link: "/france", img: france, icon: icon_france },
    ];
    const countryInfo1 = [
        { name: "Japan", link:"/japan", img: japan, icon: icon_japan },
        { name: "Italy", link:"/italy", img: italy, icon: icon_italy },
        { name: "Portugal", link:"/portugal", img: portugal, icon: icon_portugal },
        { name: "Canada", link:"/canada", img: canada, icon: icon_canada },
        { name: "Austria", link:"/austria", img: austria, icon: icon_austria },
    ]

    return (
        <section id="destinations" className="px-[6%] mt-20">
            <Heading2 text={"Top Destination"} />
            <CountriesMobile data={countryInfo} />
            <CountriesMobile data={countryInfo1} />
            <Countries data={countryInfo} />
            <Countries data={countryInfo1} />
            { more && <MoreCountries /> }
            <section className="flex justify-center mt-8">
                { !more &&
                    <ButtonDarkBorder handleClick={() => setMore(true)} >
                        More
                    </ButtonDarkBorder>
                }
            </section>
        </section>
    );
}
 
export default TopDestinations;
import React from "react";
import PriceBox from "./PriceBox";
import UserDetails from "./UserDetails";
import UserIcon from "../../../assets/icons/userIcon.png";
import { SubHeading } from "../userDetailsForm/SubHeading";
import "./PreviewInformation.css";
import { useSelector } from "react-redux";
import { AiFillFileText } from "react-icons/ai";
import FileDetails from "./FileDetails";


function PreviewInformation({setRoute, routes}) {
  const { passportUpdate } = useSelector((state) => state.passport);
  const userData = {
    fullname: passportUpdate?.result?.fullName,
    email: passportUpdate?.result?.email,
    phone: passportUpdate?.result?.phoneNumber,
    type: passportUpdate?.result?.passportType,
    numberOfApplicants: passportUpdate?.result?.numberOfApplicants
  };
  const userDetail = [
    {
      title: "Full name",
      value: userData?.fullname,
    },
    {
      title: "Email",
      value: userData?.email,
    },
    {
      title: "Phone number",
      value: userData?.phone,
    },
    {
      title: "Who are you applying for?",
      value: userData?.type,
    },
    {
      title: "Number of Applicant",
      value: userData?.numberOfApplicants,
    },
  ];

  return (
    <>
        <section className="mb-[50px]">
          <SubHeading text={"Preview Information"} />
          <section className="broken-lines mt-[30px]"></section>
          <section className="flex flex-col md:flex-row gap-y-8 md:gap-x-8 mt-[50px]">
            <section className="user-details basis-3/3 md:basis-2/3">
              <UserDetails
                heading={
                  <section className="text-pending font-medium text-xl flex items-center">
                    <img className="w-[20px] h-[20px]" src={UserIcon} alt="user" />
                    <h6 className="text-xl pl-1">User Details</h6>
                  </section>
                }
                button={
                  <button className="py-[3px] px-[10px] rounded" onClick={() => setRoute("User-Details")}>Edit</button>
                }
                userDetail={userDetail}
              />
              <FileDetails
                heading={
                  <section className="text-pending font-medium text-xl flex items-center">
                    <AiFillFileText className="text-pending w-[20px] h-[20px]" />
                    <h6 className="text-xl pl-1">File Details</h6>
                  </section>
                }
                button={
                  <button className="py-[3px] px-[10px] rounded" onClick={() => setRoute("Upload-Documents")}>Edit</button>
                }
              />
            </section>
            <section className="price-details basis-3/3 md:basis-1/3">
              <PriceBox
              />
            </section>
          </section>
        </section>
    </>
  );
}

export default PreviewInformation;

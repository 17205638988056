import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import { HomeLayout } from "./components/layouts/HomeLayout";
import { ProtectedLayout } from "./components/layouts/ProtectedLayout";
import { getUserApplication, userProfile } from "./features/auth-features/userSlice";
import {  getHome } from "./features/unauth-features/optionSlice";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import User from "./pages/user/User";
import Passport from "./pages/passport/Passport";
import VisaServices from "./pages/visaService/VisaServices";
import { TOKEN } from "./utils/token";
import PageNotFound from "./pages/404/PageNotFound";
import Confirmation from "./components/confirmation/Confirmation";
import Austria from "./pages/topDestinations/Austria";
import Canada from "./pages/topDestinations/Canada";
import France from "./pages/topDestinations/France";
import Germany from "./pages/topDestinations/Germany";
import Italy from "./pages/topDestinations/Italy";
import Japan from "./pages/topDestinations/Japan";
import Portugal from "./pages/topDestinations/Portugal";
import Swit from "./pages/topDestinations/Swit";
import Uk from "./pages/topDestinations/Uk";
import Usa from "./pages/topDestinations/Usa";
import Spain from "./pages/topDestinations/Spain";
import China from "./pages/topDestinations/China";
import SAfrica from "./pages/topDestinations/SAfrica";
import Mexico from "./pages/topDestinations/Mexico";
import Brazil from "./pages/topDestinations/Brazil";
import Ireland from "./pages/topDestinations/Ireland";
import NewZealand from "./pages/topDestinations/NewZealand";
import Australia from "./pages/topDestinations/Australia";
import Dubai from "./pages/topDestinations/Dubai";
import { getCountry } from "./features/unauth-features/generalSlice";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import Terms from "./pages/terms/Terms";
import ApplicationSteps from "./pages/applicationSteps/ApplicationSteps";
import Egypt from "./pages/topDestinations/Egypt";

function App() {
  const [ overlay, setOverlay ] = useState(false);

  const dispatch = useDispatch();
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<HomeLayout setOverlay={setOverlay} overlay={overlay} />} >
          <Route path="landing" element={<Home setOverlay={setOverlay} />} />
          <Route path="privacy-policy" element={<PrivacyPolicy  />} />
          <Route path="austria" element={<Austria />} />
          <Route path="canada" element={<Canada />} />
          <Route path="france" element={<France />} />
          <Route path="germany" element={<Germany />} />
          <Route path="italy" element={<Italy />} />
          <Route path="japan" element={<Japan />} />
          <Route path="egypt" element={<Egypt />} />
          <Route path="portugal" element={<Portugal />} />
          <Route path="swit" element={<Swit />} />
          <Route path="uk" element={<Uk />} />
          <Route path="usa" element={<Usa />} />
          <Route path="spain" element={<Spain />} />
          <Route path="dubai" element={<Dubai />} />
          <Route path="australia" element={<Australia />} />
          <Route path="newzealand" element={<NewZealand />} />
          <Route path="ireland" element={<Ireland />} />
          <Route path="brazil" element={<Brazil />} />
          <Route path="mexico" element={<Mexico />} />
          <Route path="south-africa" element={<SAfrica />} />
          <Route path="china" element={<China />} />
          <Route path="passport-application" element={<Passport />} />
          <Route  path="visa-application" element={<VisaServices /> } />
          <Route  path="*" element={<PageNotFound /> } />
          <Route  path="payment-confirmation" element={<Confirmation /> } />
          <Route  path="privacy" element={<PrivacyPolicy /> } />
          <Route  path="terms" element={<Terms /> } />
          <Route  path="application-steps" element={<ApplicationSteps /> } />
        </Route>
        <Route path="/" element={<ProtectedLayout token={TOKEN} />} >
            <Route index  element={<User />} />
        </Route>
        <Route path="login" element={<Login />} />
      </>
    )
  )


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          fetch(`https://eu1.locationiq.com/v1/reverse.php?key=pk.d4d30f759682e304dadfe6519aebef52&lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json&addressdetails=1&namedetails=1`)
            .then(response => response.json())
            .then(data => {
              dispatch(getCountry(data.address.country));
            })
            .catch(error => console.log(error));
        },
        error => console.log(error)
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }, [dispatch]);


  useEffect(() => {
    dispatch(getHome());
  },[dispatch]);

  useEffect(() => {
    if (TOKEN) {
      dispatch(userProfile());
      dispatch(getUserApplication());
    }
  }, [dispatch]);

  return (

    <div className="App">
    <div 
    className={`fixed top-0 ${overlay ? 'w-full' : "w-0" } h-full md:hidden bg-color3 transition-all ease-linear 
    duration-100 overflow-hidden z-[999]`}
    ></div>
    <RouterProvider router={router} />
  </div>
  );
}

export default App;


import { useForm } from "react-hook-form";
import { ButtonDark } from "../../buttons/Buttons"
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomSelect } from "../../inputs/CustomSelect";
import "./Hero.css"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToLocationAndDestination } from "../../../features/unauth-features/generalSlice";
import { useEffect } from "react";

const schema  = yup.object().shape({
    location: yup.string().required("current location is required"),
    destination: yup.string().required("destination is required"),
});

export const Welcome = () => {
    const draftFromStorage = JSON.parse(localStorage.getItem('visaDraft'));
    const {  visaDraft } = useSelector((state) => state.visa);
    const { countries } = useSelector((state) => state.option);
    const { country } = useSelector((state) => state.general);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const method = useForm({
        resolver: yupResolver(schema)
    })
    const { handleSubmit, setValue } = method;


    useEffect(() => {
        setValue("location", country ? country : draftFromStorage ? draftFromStorage.location : ""); 
        setValue("destination", draftFromStorage ? draftFromStorage.destination : "" );
    }, [country, visaDraft, setValue, draftFromStorage])

    const handleClick = (data) => {
        const payload = {
            location: data.location,
            destination: data.destination
        }
        dispatch(addToLocationAndDestination(payload));
        navigate('/visa-application');
    }

    const modCountries = countries?.result.map((country) => ({
        value: country.name,
        label: country.name,
    }));


    return (
        <section className="w-full py-10 px-3 xs:px-[6%] lg:pl-[8%] shadow-md bg-white rounded-md translate-y-16">
            <section className="mb-3">
                <p className="text-xl text-black"> Welcome !</p>
                <p className="text-base text-color4"> Let's make your next destination </p>
            </section>

            <section className="welcome mt-6">
                <section className="">
                    <CustomSelect
                    method={method} 
                    name={'location'} 
                    label={'Current Location'}
                    placeholder={'Nigeria'}
                    options={modCountries}
                    />
                </section>

                <section className="">
                    <CustomSelect
                    method={method}
                    name={'destination'} 
                    label={'Where is your destination?'} 
                    placeholder={'Your Destination'}
                    options={modCountries}
                    />
                </section>
                

                <section className="h-[50px] sm:h-[50px] w-fit flex items-center my-auto pt-1.5 btn-pulse">
                    <ButtonDark handleClick={handleSubmit(handleClick)}>
                        Get Started Now
                    </ButtonDark>
                </section>
            </section>

        </section>
    )
}
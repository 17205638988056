import { configureStore } from "@reduxjs/toolkit";
import optionReducer from '../features/unauth-features/optionSlice';
import generalReducer from '../features/unauth-features/generalSlice';
import visaReducer from '../features/unauth-features/visaSlice';
import loginReducer from '../features/unauth-features/loginSlice';
import passportReducer from '../features/unauth-features/passportSlice';
import userReducer from '../features/auth-features/userSlice';

export const store = configureStore({
    reducer: {
        option: optionReducer,
        general: generalReducer,
        visa: visaReducer,
        login: loginReducer,
        user: userReducer,
        passport: passportReducer,
    }
})
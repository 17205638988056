import logo from "../../assets/images/revised_logo.png";
import fb from "../../assets/images/fb.svg";
import twitter from "../../assets/images/twitter.svg";
import insta from "../../assets/images/insta.svg";
import { Link } from "react-router-dom";

export const Info = () => {
    return (
        <section className="w-full md:w-fit">
            <section className="flex justify-center md:block">
                <img className="w-[75px] h-[75px]" src={logo} alt="brand-logo" />
            </section>
            <section className="mt-5">
                <p className="w-full md:max-w-[380px] text-base text-center md:text-left text-color4">
                    At Ogavisa, we understand that obtaining a visa can be a bit complicated and time consuming process, 
                    especially for first time travelers and immigrants, that is why we are here for you. 
                </p>
            </section>
            <section className="flex justify-center md:justify-start space-x-6 mt-6">
               <Link to={"https://www.facebook.com/ogavisang"} target="_blank" > <img className={`hover:bg-pending_bg rounded-full`} src={fb} alt={"facebook-icon"} /> </Link>
               <Link to={"https://twitter.com/ogavisa"} target="_blank" > <img className={`hover:bg-pending_bg rounded-full`} src={twitter} alt={"twitter-icon"} /> </Link> 
               <Link to={"https://www.instagram.com/ogavisa/"} target="_blank" > <img className={`hover:bg-pending_bg rounded-full`} src={insta} alt={"instagram-icon"} /> </Link> 
            </section>
        </section>
    )
}
import quote from "../../../assets/images/quote.svg";

export const SliderItem = ({ name, review }) => {
    return(
        <section className="pb-20">
            <section className="flex space-x-4">
                <section className="text-xl pt-2">
                    <p className="text-darkblue font-semibold"> { name } </p>
                    {/* <p className="text-color3 font-normal"> Lecturer </p> */}
                </section>
            </section>

            <section className="flex space-x-2 mt-10">
                <section className="w-fit">
                    <img
                    src={quote} 
                    className="rounded-full" 
                    alt="reviewer"
                    />
                </section>
                <p 
                className="text-base font-medium text-color3 max-w-[600px] pt-4"
                > 
                    { review }
                </p>            
            </section>
        </section>
    )
}
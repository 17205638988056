import { Content } from "./Content"
import { InfoHeader } from "./InfoHeader"
import { BsAirplane } from "react-icons/bs"
import { useSelector } from "react-redux";
import { OnlyDate } from "../../excerpts/OnlyDate";

const content = [
    { text1: "Full name:", text2: "John Doe" },
    { text1: "Email", text2: "johndoe@gmail.com" },
    { text1: "Phone number", text2: "+2348130586985" },
    { text1: "Estimated start of your trip:", text2: "Feb 21, 2023" },
    { text1: "Estimated end of your trip:", text2: "Feb 28, 2023" },
];

export const TripInformation = ({  setRoute, setCurrentStep }) => {
    const { visaUpdate } = useSelector((state) => state.visa);
    return (
        <section className="w-[700px] md:w-full 2xl:w-[1000px]">
            <InfoHeader icon={<BsAirplane className="text-lg mr-2" />} header={"Trip Information"} 
            editClick={() => {
                setRoute("trip-information")
                setCurrentStep(2)
            }} 
            />
            <section className="grid grid-cols-3 gap-4 mb-6">
                <Content text1={content[0].text1} text2={visaUpdate?.result?.fullName} />
                <Content text1={content[1].text1} text2={visaUpdate?.result?.email} />
                <Content text1={content[2].text1} text2={visaUpdate?.result?.phone} />
                <Content text1={content[3].text1} text2={ <OnlyDate date={visaUpdate?.result?.startTripDate} /> } />
                <Content text1={content[4].text1} text2={ <OnlyDate date={visaUpdate?.result?.endTripDate} /> } />
            </section>
        </section>
    )
}